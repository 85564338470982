import { createSlice } from '@reduxjs/toolkit';
const initialState = {};

const TemplateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    initTemplate (state, action) {
      const { template } = action.payload;
      return {
        ...state,
        ...template
      };
    }
  }
});

export const {
  initTemplate
} = TemplateSlice.actions;
export default TemplateSlice.reducer;
