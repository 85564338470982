import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "src/components/common/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";

import {
  moveToHistory,
  nextMessage,
  setCurrent,
  toggleRendering,
} from "src/store/slices/MessageSlice";

import { SendMessage } from "src/store/thunks/MessageThunk";

import { send_text_by_language } from "../../constants/texts";

import enGB from "date-fns/locale/en-GB"; // British English
import enUS from "date-fns/locale/en-US"; // American English (default - no setup needed)
import es from "date-fns/locale/es"; // Spanish
import fr from "date-fns/locale/fr"; // French
import sv from "date-fns/locale/sv"; // Swedish
import fi from "date-fns/locale/fi"; // Finnish
import nb from "date-fns/locale/nb"; // Norwegian (bokmål)
import da from "date-fns/locale/da"; // Danish
import { Send } from "react-feather";
import Button from "src/components/common/Button";

registerLocale("es", es);
registerLocale("fr", fr);
registerLocale("sv", sv);
registerLocale("fi", fi);
registerLocale("en-GB", enGB);
registerLocale("en-US", enUS);
registerLocale("nb", nb);
registerLocale("da", da);

export const HubertDatePicker = forwardRef(({ id }, ref) => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());

  const Interview = useSelector((state) => state.Interview);
  let message = useSelector((state) => state.Messages.current);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    document.getElementById(`${"j-chat"}`).classList.add("full");
  }, []);

  const send = () => {
    if (!date) return setErrorMessage("You must do something.");
    else setErrorMessage("");

    const text = date.toLocaleDateString();

    const candidate_answer = {
      sender: "candidate",
      // answers: [`${message.candidate_answer_pre_text}: ${date}`]
      answers: [`${text}`],
    };

    dispatch(moveToHistory());
    dispatch(nextMessage({ next: candidate_answer }));

    dispatch(
      SendMessage({
        interview_id: Interview._id,
        qutter_oid: message._id,
        qutter_id: message.id,
        language: message.language,
        type: message.type,

        date: date,
        value: date,
        text: text,
      })
    )
      .then((res) => {
        document.getElementById(`${"j-chat"}`).classList.remove("full");
        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());
        dispatch(setCurrent({ current: res.payload.data }));
      })
      .catch((res) => {
        console.log("SendMessage Thunk Error: ", res);
      });
  };

  const picker_language =
    message.language === "en-gb"
      ? "en-GB"
      : message.language === "en-us"
      ? "en-US"
      : message.language;

  return (
    <form ref={ref} className="mt-5 mb-3 mx-3 form" id="EktCEj">
      <p style={{ fontWeight: 600 }}>{message.help_text}</p>
      <DatePicker
        selected={date}
        onChange={(date) => {
          setDate(date);
        }}
        dateFormat="yyyy-MM-dd"
        locale={picker_language}
        placeholderText="Weeks start on Monday"
        inline
        className="form-control"
        minDate={new Date()}
        showMonthYearPicker={message.type === "date-picker" ? false : true}
      />
      <div
        id="input_error_container"
        className="text-center w-100"
        style={{ minHeight: "18px" }}
      >
        <p
          id="input_error"
          className={`m-0 ${errorMessage ? errorMessage : "hidden"}`}
          style={{ color: "red" }}
        >
          {errorMessage}
        </p>
      </div>
      <Button
        id="formButton"
        variant="primary"
        iconRight={<Send />}
        onClick={send}
        expanded
      >
        {send_text_by_language[message.language]}
      </Button>
    </form>
  );
});
