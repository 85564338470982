import clsx from "clsx";

export const BaseMessage = ({ sender, message, bubble }) => {
  return (
    <div
      className={clsx(
        "flex",
        sender === "hubert" ? "flex-col" : "flex-col-reverse"
      )}
    >
      <span
        className={clsx("pp", sender === "hubert" ? "left" : "right")}
      ></span>
      <div className="m-0" dangerouslySetInnerHTML={{ __html: bubble }}></div>
    </div>
  );
};
