import { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Send } from "react-feather";
import { GapDropdown } from "./GapDropdown";
import { Text } from "src/components/common";
import {
  toggleHubertTyping,
  moveToHistory,
  nextMessage,
  setCurrent,
  setPending,
  toggleRendering,
} from "src/store/slices/MessageSlice";
import { SendMessage } from "src/store/thunks/MessageThunk";
import "./FillInTheGaps.scss";

export const FillInTheGaps = forwardRef(({ message, message_idx }, ref) => {
  const dispatch = useDispatch();
  const Interview = useSelector((state) => state.Interview);
  const question = useSelector((state) => state.Messages.current);

  const [error, set_error] = useState(false);
  const [error_text, set_error_text] = useState("");
  const [values, set_values] = useState(false);

  const on_send = () => {
    const number_answers = Object.keys(values).length;
    if (number_answers !== message.dropdowns.length) {
      set_error(true);
      set_error_text("You must give an answer to all.");
      return;
    }

    set_error(false);
    set_error_text("");

    let answers = [];
    for (let i = 0; i < message.dropdowns.length; i++) {
      const dropdown = message.dropdowns[i];
      const answer = values[dropdown.id];
      answers.push(answer);
      const candidate_answer = {
        sender: "candidate",
        answers: [`${answer.label}`],
      };

      dispatch(moveToHistory());
      dispatch(nextMessage({ next: candidate_answer }));
    }

    dispatch(toggleHubertTyping({ value: true }));
    dispatch(
      SendMessage({
        interview_id: Interview._id,
        candidate_id: Interview.candidate_id,
        job_id: Interview.job_id,
        template_id: Interview.template_id,
        application_id: Interview.application_id,
        qutter_oid: question._id,
        qutter_id: question.id,
        language: question.language,
        type: question.type,
        value: answers,
      })
    )
      .then((res) => {
        const payload = res.payload;
        dispatch(toggleHubertTyping({ value: false }));
        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());

        dispatch(setCurrent({ current: payload.data }));
        dispatch(setPending({ pending: payload.pending }));
      })
      .catch((res) => {
        console.log("SendMessage Thunk Error: ", res);
      });
  };

  return (
    <form className="fill-gaps-wrapper">
      <Text className="mb-2" block>
        <span dangerouslySetInnerHTML={{ __html: message.pre_text }} />
      </Text>

      <div>
        <Text className="gaps-text" block>
          <span dangerouslySetInnerHTML={{ __html: message.gaps_text }} />
        </Text>

        {message.dropdowns.map((dropdown, idx) => {
          return (
            <GapDropdown
              key={`gap-dropdown-${idx}`}
              dropdown={dropdown}
              values={values}
              set_values={set_values}
            />
          );
        })}
      </div>

      {error_text && (
        <div id="input_error_container" className="text-center mt-2">
          <p
            id="input_error"
            className={`text-danger ${error ? "" : "hidden"}`}
          >
            {error_text}
          </p>
        </div>
      )}

      <button
        id="formButton"
        className="send-button"
        onClick={() => {
          on_send();
        }}
      >
        <Text>Send</Text>
        <Send size={16} />
      </button>
      <div ref={ref} />
    </form>
  );
});
