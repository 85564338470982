import React from "react";
import { Text } from "src/components/common";

export const GapDropdown = ({ dropdown, values, set_values }) => {
  return (
    <div className="gap-dropdown-wrapper">
      <Text block weight="semibold" className="label">
        {dropdown.label}
      </Text>
      <select
        onChange={(c) => {
          const v = parseInt(c.target.value);
          let pick = dropdown.options.find((o) => o.value === v);
          const data = {
            dropdown_id: dropdown.id,
            value: v,
            text: pick.label,
            label: pick.label,
            updated_at: new Date(),
          };
          set_values({
            ...values,
            [dropdown.id]: data,
          });
        }}
      >
        {dropdown.options.map((option, idx) => {
          return (
            <option key={`gap-dropdown-option-${idx}`} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
