import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faQuestion } from "@fortawesome/pro-solid-svg-icons";

import { Header } from "src/components/Header";

import TypingIndicator from "src/components/TypingIndicator";
import CurrentMessage from "src/components/Messages/CurrentMessage";
import MessageHistory from "src/components/MessageHistory";
import { InputArea } from "src/components/Input";
import QuestionProgress from "src/components/QuestionProgress";

export const InterviewWidget = ({
  job,
  template,
  interview,
  candidate,
  application,
}) => {
  const [open, set_open] = useState(false);
  const [unread, set_unread] = useState(0);

  useEffect(() => {
    if (job && job.title) {
      document.title = `Interview - ${job.title}`;
    }
  }, [job]);

  if (!job && !template && !interview && !candidate && !application)
    return <span>Null</span>;

  return (
    <div id="jarvisContainer" className="jarvis">
      <div
        id="chat-widget-launcher"
        className={`chat-widget-launcher`}
        onClick={() => {
          set_open(!open);
          function notisBadge(show, text) {
            // show ?
            //   jQuery('#notisBadge').removeClass('hidden').text(text) :
            //   jQuery('#notisBadge').addClass('hidden').text(text);
          }
          notisBadge(false, 0);
        }}
      >
        <div className="m-auto">
          {open ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faQuestion} />
          )}
        </div>
        {/* Notis om nya meddelanden när chat är stängd */}
        <span id="notisBadge" className="notisBadge">
          {unread}
        </span>
      </div>

      {/* <Interview /> */}

      <div
        id="bubbleContainer"
        className={`bubbleChatBox chat-widget ${open ? "show" : ""}`}
      >
        <Header job={job} template={template} info_tab={true} refresh={true} />

        {/* <div
        id='infoBanner'
        className='infoBanner h-100 w-100'
      >
        <div
          className='exit'
          onClick={() => {
            // infoBanner.toggle();
          }}
        ></div>
      </div> */}

        {/* Chat */}
        <div id="j-chat" className="j-chat">
          <div
            id="chatContainer"
            className="chatContainer w-100 j-inner"
            style={{
              position: "absolute",
              top: "80px",
              bottom: "80px",
              overflowY: "auto",
              width: "420px",
              height: "520px",
              padding: "10px 20px 20px 20px",
              backgroundColor: "#f2f2f7 !important",
              transition: "0.25s",
              scrollBehavior: "smooth",
              zIndex: "-2",
              right: "0px",
            }}
          >
            <MessageHistory />
            <CurrentMessage />
          </div>

          <TypingIndicator />
        </div>

        {/* Input */}
        <InputArea />
        <QuestionProgress interview={interview} />
      </div>
    </div>
  );
};
