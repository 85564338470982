import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { LoadPreview, PreviewInterview } from "./pages/PreviewInterview";
import { LoadInterview, InterviewContainer } from "./pages";
import { Suspense, lazy } from "react";
import MainLayout from "./layout/MainLayout/MainLayout";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";

import "./App.css";

const Job = lazy(() => import("./job/Job"));
const TemplateTest = lazy(() => import("./components/Testing/TemplateTest"));
const Test = lazy(() => import("./test.js"));

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<NoMatch />} />

          <Route path=":interview_id" element={<LoadInterview />}>
            <Route index element={<InterviewContainer widget={false} />} />
          </Route>

          <Route path="widget/:interview_id" element={<LoadInterview />}>
            <Route index element={<InterviewContainer widget={true} />} />
          </Route>

          <Route path="preview/:interview_id" element={<LoadPreview />}>
            <Route index element={<PreviewInterview />} />
          </Route>

          <Route
            path="job"
            element={
              <Suspense
                fallback={<LoadingScreen subtitle="Creating interview..." />}
              >
                <Job />
              </Suspense>
            }
          >
            <Route index element={<Test />} />
            <Route
              path=":job_id"
              element={<InterviewContainer widget={false} />}
            />
          </Route>

          <Route
            path="template/test"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <TemplateTest />
              </Suspense>
            }
          >
            <Route index element={<Test />} />
            <Route
              path=":template_id"
              element={<InterviewContainer widget={false} />}
            />
          </Route>

          <Route path="preview" element={<Test />}>
            <Route index element={<Test />} />
            <Route path=":interview_id" element={<Test />} />
            <Route path=":question_id" element={<Test />} />
          </Route>

          <Route path="teams" element={<Test />}>
            <Route path=":teamId" element={<Test />} />
            <Route path="new" element={<Test />} />
            <Route index element={<Test />} />
          </Route>
        </Route>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
