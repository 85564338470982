import { useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sleep2 } from "src/utils/utils";

import {
  toggleRendering,
  toggleHubertTyping,
} from "src/store/slices/MessageSlice";

import { BaseMessage } from "../../components/Messages/BaseMessage";
import formatToRelativeTime from "date-fns/formatRelative";
import { useMessageTime } from "./MessageTimeProvider/MessageTimeProvider";
import useScrollToComponent from "src/hooks/useScrollToComponent";
import clsx from "clsx";

const SimulateMessage = ({
  wait,
  sender,
  message,
  bubble,
  is_last_message,
  messageType,
}) => {
  const dispatch = useDispatch();
  const isHubertTyping = useSelector((state) => state.Messages.htyping);
  const isChatRendering = useSelector((state) => state.Messages.is_rendering);
  const [render, setRender] = useState(!wait || wait === 0 ? true : false);
  const [searchParams] = useSearchParams();
  const { setFocusedRef, focusedRef } = useMessageTime();
  const messagesEndRef = useRef(null);
  const messageTimeRef = useRef(null);
  const scrollToMessage = useScrollToComponent(messagesEndRef);

  const toggleMessageTime = () => {
    setFocusedRef(messageTimeRef === focusedRef ? undefined : messageTimeRef);
  };

  useEffect(() => {
    if (render === false) {
      sleep2(() => {
        setRender(true);
        if (is_last_message) {
          dispatch(toggleRendering({ value: false }));
          dispatch(toggleHubertTyping({ value: false }));
        }
      }, wait);
    }

    scrollToMessage();
  }, [dispatch, is_last_message, render, wait, scrollToMessage]);

  let side = "chat-bubble-element-wrapper";
  if (sender === "hubert") {
    side += " bodil hubert-bubble-wrapper";
  } else {
    side += " me candidate-bubble-wrapper";
  }

  const date =
    message && message.created_at ? new Date(message.created_at) : new Date();

  return (
    <>
      {searchParams.get("debug") === "true" ? (
        <span>Render: {render.toString()}</span>
      ) : null}

      <div className={`not-selectable ${side} ${render ? "" : "hidden"}`}>
        <div
          ref={messagesEndRef}
          onClick={toggleMessageTime}
          className={clsx(
            "chat-bubble-element",
            sender === "hubert" ? "left" : "right",
            (isChatRendering || isHubertTyping) &&
              messageType === "current" &&
              "ml-8"
          )}
        >
          <BaseMessage
            sender={sender}
            message={message}
            bubble={bubble}
            is_last_message={is_last_message}
          />
        </div>
        <div ref={messageTimeRef} className="message-time">
          <span>{formatToRelativeTime(date, new Date())}</span>
        </div>
      </div>
    </>
  );
};

export default SimulateMessage;
