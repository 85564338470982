import { forwardRef, useState } from "react";
import { useDispatch } from "react-redux";

import DatePicker from "src/components/common/DatePicker";
import "react-datepicker/dist/react-datepicker.css";

import { ConditionedOn } from "../ConditionedOn";

import { updateReusableDateValue } from "src/store/slices/ReusableSlice";

export const ReusableDate = forwardRef(({ reusable, reusable_idx }, ref) => {
  const dispatch = useDispatch();
  const qutter = reusable.qutter;
  const [show, set_show] = useState(true);
  if (!qutter) return <></>;

  const on_date_change = (date) => {
    const d = new Date(date);
    dispatch(
      updateReusableDateValue({
        qutter_id: reusable.qutter_id,
        value: d.toUTCString(),
        original_value: reusable.original_value,
        label: d.toUTCString(),
        text: d.toUTCString(),
      })
    );
  };

  const picker_language =
    reusable.language === "en-gb"
      ? "en-GB"
      : reusable.language === "en-us"
      ? "en-US"
      : reusable.language;

  return (
    <>
      <ConditionedOn reusable={reusable} set_show={set_show} />
      <div className={`reusable-date ${show ? "" : "d-none"}`}>
        <div className="question-wrapper">
          <i>
            <p
              className="m-0"
              dangerouslySetInnerHTML={{
                // __html: `${reusable.question.type}: ${reusable.question.text}`,
                __html: reusable.question.text,
              }}
            ></p>
          </i>
        </div>

        <div ref={ref} className="text-center">
          <DatePicker
            selected={new Date(reusable.value)}
            onChange={on_date_change}
            dateFormat="yyyy-MM-dd"
            locale={picker_language}
            placeholderText="Weeks start on Monday"
            inline
            className="form-control"
            minDate={new Date()}
            showMonthYearPicker={
              reusable.question.type === "date-picker" ? false : true
            }
          />
        </div>
      </div>
    </>
  );
});
