import {
  PushKey,
  SetCurrentKey,
  PushBackspace,
  PushChange,
} from "src/store/slices/QmetricSlice";

import {
  toggleCandidateTyping,
  toggleHubertTyping,
} from "src/store/slices/MessageSlice";

export const adjust = (value) => {
  const input = document.getElementById("inputText");
  const input_container = document.getElementById("inputContainer");
  const chat_container = document.getElementById("chatContainer");
  let container_height;
  const rows = value.split(/\r\n|\r|\n/).length;

  if (rows === 1) container_height = 80;
  else container_height = rows * 16 + 80;

  // input_container.style.height = `${container_height}px`;
  // chat_container.style.transform = `translateY(-${container_height - 80}px)`;
  chat_container.scrollTop = chat_container.scrollHeight;

  /*
  (value === '') ? 
    (input_height = 0) : 
    (input_height = Math.round((input.scrollHeight - 18) / 18));
  container_height = (input_height - 1) * 18 + 80;
  container.style.height = `${container_height}px`;
  chat_container.style.transform = `translateY(-${container_height - 80}px)`;
  chat_container.scrollTop = chat_container.scrollHeight;
  */
};

export const onKeyDown = (event, dispatch, qutter_id) => {
  const keycode = event.keyCode ? event.keyCode : event.which;
  const value = event.target.value;

  dispatch(PushKey({ qutter_id, keycode }));
  dispatch(SetCurrentKey({ qutter_id, keycode }));

  if (keycode === 8) dispatch(PushBackspace({ qutter_id }));
  if (keycode === 13 && event.shiftKey) {
    // console.log('onKeyDown value', value)
    adjust(value);
  }
  // console.log('onKeyDown value', value)
  // adjust(value);
};

export const onInput = (event, dispatch, qutter_id) => {
  const value = event.target.value;
  dispatch(PushChange({ qutter_id, value }));
};

export const onChange = (event, dispatch, set_current_text) => {
  const value = event.target.value;
  // console.log('onChange value', value)
  adjust(value);

  set_current_text(value);
  if (value && value.length) {
    dispatch(toggleCandidateTyping({ value: true }));
    dispatch(toggleHubertTyping({ value: false }));
  } else {
    dispatch(toggleCandidateTyping({ value: false }));
  }
};

export const onKeyUp = (event, set_text, sendCallback) => {
  const keycode = event.keyCode ? event.keyCode : event.which;
  const value = event.target.value;
  // console.log('onKeyUp value', value)

  if (value === "\n") set_text("");
  else if (keycode === 13 && event.shiftKey === false) {
    sendCallback();
  }
};
