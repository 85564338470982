import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  nextMessage,
  moveToHistory,
  toggleCandidateTyping,
  toggleHubertTyping,
} from "src/store/slices/MessageSlice";

const DebugActionBar = () => {
  const dispatch = useDispatch();
  const htyping = useSelector((state) => state.Messages.htyping);
  const ctyping = useSelector((state) => state.Messages.ctyping);
  const [input, setInput] = useState(null);

  const sampleHubert = { sender: "hubert", answers: ["Hello from Hubert"] };
  const sampleCandidate = {
    sender: "candidate",
    answers: ["Hello from Candidate"],
  };

  useEffect(() => {
    const element = document.getElementById(`${"j-chat"}`);
    if (element) {
      setInput(element.classList.contains("full"));
    }
  }, []);

  return (
    <Row className="m-1">
      <Col>
        <span
          className="btn btn-black btn-sm m-1"
          onClick={() => {
            dispatch(moveToHistory());
            dispatch(nextMessage({ next: sampleHubert }));
          }}
        >
          Example Hubert Message
        </span>

        <span
          className="btn btn-black btn-sm m-1"
          onClick={() => {
            dispatch(moveToHistory());
            dispatch(nextMessage({ next: sampleCandidate }));
          }}
        >
          Example Candidate Message
        </span>

        <span
          className="btn btn-black btn-sm m-1"
          onClick={() => {
            dispatch(toggleHubertTyping());
          }}
        >
          {htyping ? (
            <span>Hubert typing off</span>
          ) : (
            <span>Hubert typing on</span>
          )}
        </span>

        <span
          className="btn btn-black btn-sm m-1"
          onClick={() => {
            dispatch(toggleCandidateTyping());
          }}
        >
          {ctyping ? (
            <span>Candidate typing off</span>
          ) : (
            <span>Candidate typing on</span>
          )}
        </span>

        <span
          className="btn btn-black btn-sm m-1"
          size={"xs"}
          onClick={() => {
            let check = document
              .getElementById(`${"j-chat"}`)
              .classList.contains("full");
            if (check) {
              setInput(false);
              document.getElementById(`${"j-chat"}`).classList.remove("full");
            } else {
              setInput(true);
              document.getElementById(`${"j-chat"}`).classList.add("full");
            }
          }}
        >
          {input ? <span>Add input area</span> : <span>Remove input area</span>}
        </span>

        <span className="btn btn-black btn-sm m-1" onClick={() => {}}>
          <span>Example Single Select</span>
        </span>
      </Col>
    </Row>
  );
};

export default DebugActionBar;
