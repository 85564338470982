import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import formatToRelativeTime from "date-fns/formatRelative";
import { enGB, enUS, sv, de, fr, fi, da, nn, es } from 'date-fns/locale';

import { reuseDateString } from "../constants";

function resolveLocale (language) {
  switch(language) {
    case 'sv-se':
      return { locale: sv };
    case 'en-gb':
      return { locale: enGB };
    case 'en-us':
      return { locale: enUS };
    case 'de-de':
      return { locale: de };
    case 'da-dk':
      return { locale: da };
    case 'no-no':
      return { locale: nn };
    case 'fi-fi':
      return { locale: fi };
    case 'fr-fr':
      return { locale: fr };
    case 'es-es':
      return { locale: es };
    case 'es-mx':
      return { locale: es };
    default:
      return { locale: enGB };
  }
}

const ReusablesDate = () => {
  const [locale, setLocale] = useState(null);
  const date = useSelector((state) => state.Reusables.date);
  const question = useSelector(state => state.Messages.current);

  useEffect(() => {
    setLocale(resolveLocale(question.language));
  }, [question]);

  if (!date) return null;
  if (!locale) return null;

  return (
    <h1 className="text-base">
      {`${reuseDateString[question.language]} ${formatToRelativeTime(
        new Date(date),
        new Date(),
        locale
      )}`}
    </h1>
  );
};

export default ReusablesDate;
