import { useEffect, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { AddQmetrics } from "src/store/slices/QmetricSlice";

export const Open = forwardRef(({ message }, ref) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById(`${"j-chat"}`).classList.remove("full");
    if (message.id) {
      dispatch(AddQmetrics({ qutter_id: message?.id }));
    }
  }, [dispatch, message?.id]);

  return <div ref={ref} />;
});
