import { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import DebugActionBar from "src/components/Testing/DebugActionBar";
import { Interview, InterviewWidget } from "src/pages";
import LoadingScreen from "src/components/LoadingScreen/LoadingScreen";
import OverviewSection from "src/components/OverviewSection";
import "./InterviewContainer.scss";

export const InterviewContainer = ({ widget }) => {
  const [template, candidate, application, job, interview] = useOutletContext();
  const [searchParams] = useSearchParams();
  const [apply, set_apply] = useState(false);

  useEffect(() => {
    if (job && job.title) {
      document.title = `Interview - ${job.title}`;
    }
  }, [job]);

  if (!job && !template && !interview && !candidate && !application)
    return <LoadingScreen />;

  if (widget) {
    return (
      <div>
        {searchParams.get("debug") === "true" && <DebugActionBar />}

        <div className="container mt-5">
          <div className="jumbotron">
            <h1 style={{ fontSize: "84px" }}>Sky Job Ad in Avature</h1>
            <p>This is a demo of how the process could look like.</p>
            <p>Sky's job ad in Avature.</p>

            <div className="text-center">
              <span
                className="btn btn-lg btn-primary"
                onClick={() => {
                  set_apply(true);
                }}
              >
                Apply now!
              </span>
            </div>
          </div>
        </div>

        {apply ? (
          <InterviewWidget
            template={template}
            candidate={candidate}
            application={application}
            job={job}
            interview={interview}
          />
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="interview-container-wrapper">
        {/* <div className='inner-wrapper'> */}
        {searchParams.get("debug") === "true" && <DebugActionBar />}
        {/* <QuestionsLeft interview={interview} /> */}
        <OverviewSection interview={interview} />
        <Interview
          template={template}
          candidate={candidate}
          application={application}
          job={job}
          interview={interview}
        />
        {/* </div> */}
      </div>
    );
  }
};
