import React, { forwardRef, useState } from "react";
import { ReusableOption } from "./ReusableOption";
import { ConditionedOn } from "../ConditionedOn";
import clsx from "clsx";

export const ReusableSingleSelect = forwardRef(
  ({ reusable, reusable_idx }, ref) => {
    const qutter = reusable.qutter;
    const [show, set_show] = useState(true);
    if (!qutter) return null;

    return (
      <>
        <ConditionedOn reusable={reusable} set_show={set_show} />
        <div
          ref={ref}
          className={clsx("reusable-single-select", !show && "hidden")}
        >
          <div className="question-wrapper">
            <em>
              <i
                className="m-0"
                dangerouslySetInnerHTML={{
                  // __html: `${reusable.question.type}: ${reusable.question.text}`,
                  __html: reusable.question.text,
                }}
              />
            </em>
          </div>

          <div role="list" className="flex flex-col gap-1 my-1">
            {qutter.options.map((option, idx) => {
              return (
                <ReusableOption
                  key={option.id}
                  option={option}
                  qutter_id={reusable.qutter_id}
                  option_idx={idx}
                  value={reusable.value}
                  reusable_idx={reusable_idx}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
);
