import React, { useState, useEffect } from 'react';
import { FormLabel, FormError } from '../..';

export const FormText = ({
  form,
  form_idx,
  values,
  set_values
}) => {
  const [text, set_text] = useState(values && values.hasOwnProperty(form.id) ? values[form.id].value : '');

  useEffect(() => {
    const data = {
      id: form.id,
      value: '',
      updated_at: new Date(),
      is_mandatory: form.is_mandatory,
      hubiverse_property: form.hubiverse_property,
      candidate_property: form.candidate_property,
    };
    set_values({
      ...values,
      [form.id]: data
    })
  }, []);

  return (
    <div className="form-group">
      <div className="form-label-wrapper">
        <FormLabel label={form.label} is_mandatory={form.is_mandatory} />
      </div>
      <input
        type='text'
        key={`form-text`}
        defaultValue={text}
        placeholder={`${form.placeholder}`}
        className='form-control'
        onChange={(e) => {
          const data = {
            id: form.id,
            value: e.target.value,
            updated_at: new Date(),
            is_mandatory: form.is_mandatory,
            hubiverse_property: form.hubiverse_property,
            candidate_property: form.candidate_property,
          };
          set_values({
            ...values,
            [form.id]: data
          })
          set_text(e.target.value)
        }}
      />
      <div className="form-error-wrapper">
        <FormError
          has_error={form.has_error}
          error_message={form.error_message}
        />
      </div>
    </div>
  );
}
