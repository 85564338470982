import { useDispatch, useSelector } from "react-redux";
import { makeid } from "src/utils/utils";

import {
  toggleHubertTyping,
  removeOptions,
  moveToHistory,
  nextMessage,
  setCurrent,
  setPending,
  toggleRendering,
} from "src/store/slices/MessageSlice";

import { SendMessage } from "src/store/thunks/MessageThunk";
import { pushReusables } from "src/store/slices/ReusableSlice";

const Option = ({ message, message_idx, option_id, option_idx }) => {
  const dispatch = useDispatch();
  const Interview = useSelector((state) => state.Interview);
  const question = useSelector((state) => state.Messages.current);
  const option = message.options[option_idx];

  const onOptionClick = () => {
    let label = option.label;

    const actions = option.actions;
    if (actions && actions.length > 0) {
      label = option.label.replace(/<\/?[^>]+(>|$)/g, "");
    }

    const candidate_answer = {
      sender: "candidate",
      answers: [`${label}`],
    };

    let immediate_replies = null;
    if (option.immediate_replies && option.immediate_replies.length > 0) {
      immediate_replies = {
        sender: "hubert",
        answers: option.immediate_replies,
        wait: 0,
      };
    }

    dispatch(removeOptions());
    dispatch(moveToHistory());
    dispatch(nextMessage({ next: candidate_answer }));

    dispatch(toggleHubertTyping({ value: true }));

    if (immediate_replies) {
      dispatch(moveToHistory());
      dispatch(nextMessage({ next: immediate_replies }));
    }

    dispatch(
      SendMessage({
        interview_id: Interview._id,
        candidate_id: Interview.candidate_id,
        job_id: Interview.job_id,
        template_id: Interview.template_id,
        application_id: Interview.application_id,
        qutter_oid: question._id,
        qutter_id: question.id,
        language: question.language,
        type: question.type,

        label: label,
        text: label,
        value: option.id,
      })
    )
      .then((res) => {
        const payload = res.payload;
        // document.getElementById(`${'j-chat'}`).classList.remove('full');

        dispatch(toggleHubertTyping({ value: false }));

        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());

        dispatch(setCurrent({ current: payload.data }));
        dispatch(setPending({ pending: payload.pending }));

        if (payload.reusable && payload.reusable.order.length > 0) {
          dispatch(pushReusables({ reusable: payload.reusable }));
        }
      })
      .catch((res) => {
        console.log("SendMessage Thunk Error: ", res);
      });
  };

  return (
    <div key={`${makeid(8)}`} className="input-bubble-wrapper">
      <div
        className="answer-button"
        data-value={`${option.id}`}
        // payload={`${option.value}`}
        // reply={`${option.label}`}
        onClick={() => {
          onOptionClick();
        }}
        dangerouslySetInnerHTML={{ __html: option.label }}
      ></div>
    </div>
  );
};

export default Option;
