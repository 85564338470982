import { ComponentProps } from "react";
import "./LoadingScreen.scss";

export interface TransitionPageProps extends ComponentProps<"div"> {
  title?: string;
  subtitle?: string;
}

const LoadingScreen = ({
  title = "Your Interview",
  subtitle = "Getting things ready...",
  ...transitionPageProps
}: TransitionPageProps) => {
  return (
    <section {...transitionPageProps} className="loader-container">
      <div className="logo">
        <div className="hubert-left">
          <div className="inner"></div>
        </div>
        <div className="hubert-right "></div>
      </div>
      <div>
        <p className="text-sm md:text-base">{title}</p>
        <h3 className="text-lg font-medium md:text-3xl">{subtitle}</h3>
      </div>
    </section>
  );
};

export default LoadingScreen;
