import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateReusableApplicability } from 'src/store/slices/ReusableSlice';

export const ConditionedOn = ({
  reusable,
  set_show
}) => {
  const dispatch = useDispatch();
  const map = useSelector(state => state.Reusables.map);
  const [condition, set_condition] = useState(null);

  useEffect(() => {
    if (reusable.condition === null) return;
    set_condition(reusable.condition);
  }, []);

  useEffect(() => {
    if (!condition) return;
    const current_value = map[condition.qutter_id].value;
    if (current_value !== condition.value) {
      // Don't show in reusable list
      set_show(false);
      // Update as not applicable
      dispatch(updateReusableApplicability({
        qutter_id: reusable.qutter_id,
        applicable: false
      }));
    }
    else {
      set_show(true);
      // Update as applicable
      dispatch(updateReusableApplicability({
        qutter_id: reusable.qutter_id,
        applicable: true
      }));
    }
  }, [map]);

  return (null);
};
