import React from 'react';
import ReactDOM from 'react-dom';

// Rollbar
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'

// Redux
import { Provider } from 'react-redux';
import { store } from './store/store';

// SCSS/
import './../src/styles/main.scss'
import './index.css';
import './xcustom.scss';
import './style/sass/custom.scss';

import './helpers/initFA';

// APP
import App from './App';

const rollbarConfig = {
  accessToken: 'f1d8707f58c64c3b89b8d45f88c4d446',
  environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'DEVELOPMENT',
};

// function TestError() {
//   const a = null;
//   return a.hello_chat();
// }

ReactDOM.render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <App />
          {/* <TestError /> */}
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
