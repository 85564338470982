import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { write_something } from "src/utils/constants/constants";

import {
  nextMessage,
  moveToHistory,
  toggleHubertTyping,
  toggleCandidateTyping,
  setCurrent,
  setPending,
} from "src/store/slices/MessageSlice";
import { SendOpenMessage } from "src/store/thunks/MessageThunk";

import { Reset, VisibilityChange, Pastes } from "src/store/slices/QmetricSlice";

import { onInput, onKeyDown, onKeyUp, onChange, adjust } from "../utils/utils";
import { Send } from "react-feather";

const Input = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [is_disabled, set_is_disabled] = useState(true);
  const [ship, set_ship] = useState(false);
  const [current_text, set_current_text] = useState("");
  const [texts, set_texts] = useState([]);
  const [units, set_units] = useState([]);
  const [interval, set_interval] = useState(null);

  const Interview = useSelector((state) => state.Interview);
  const question = useSelector((state) => state.Messages.current);
  const ctyping = useSelector((state) => state.Messages.ctyping);
  const qmetrics = useSelector((state) => state.Qmetrics[question.id]);

  const handle_visibility_change = useCallback(
    (event) => {
      // TODO: Visibility change - slice and update
      try {
        const now = new Date().toUTCString();
        let visibility_change = null;
        if (document.hidden) visibility_change = { left: true, date: now };
        if (!document.hidden) visibility_change = { returned: true, date: now };
        if (visibility_change !== null)
          dispatch(
            VisibilityChange({
              qutter_id: question.id,
              visibility_change,
            })
          );
      } catch (e) {
        console.log("E", e);
      }
    },
    [dispatch, question.id]
  );

  const send = (event) => {
    set_is_disabled(true);
    adjust("");
    set_current_text("");
    dispatch(toggleCandidateTyping({ value: false }));
    dispatch(toggleHubertTyping({ value: true }));

    const payload = {
      interview_id: Interview._id,
      candidate_id: Interview.candidate_id,
      job_id: Interview.job_id,
      template_id: Interview.template_id,
      application_id: Interview.application_id,
      qutter_oid: question._id,
      qutter_id: question.id,
      language: question.language,
      expected_language: question.language,
      type: "open",

      texts: texts,
      units: units,
    };
    dispatch(SendOpenMessage(payload))
      .then((res) => {
        set_texts([]);
        set_units([]);
        set_ship(false);

        dispatch(moveToHistory());
        dispatch(setCurrent({ current: res.payload.data }));
        dispatch(setPending({ pending: res.payload.pending }));
      })
      .catch((error) => {
        console.log("SendOpenMessage Thunk Error: ", error);
      });
  };

  useEffect(() => {
    if (is_disabled) set_is_disabled(false);
    if (!is_disabled) ref.current.focus();
  }, [question, is_disabled, qmetrics]);

  useEffect(() => {
    if (!ctyping && ship === true && texts.length > 0) {
      set_is_disabled(true);
      dispatch(toggleCandidateTyping({ value: false }));
      dispatch(toggleHubertTyping({ value: true }));

      const payload = {
        interview_id: Interview._id,
        candidate_id: Interview.candidate_id,
        job_id: Interview.job_id,
        template_id: Interview.template_id,
        application_id: Interview.application_id,
        qutter_oid: question._id,
        qutter_id: question.id,
        language: question.language,
        expected_language: question.language,
        type: "open",

        texts: texts,
        units: units,
      };
      dispatch(SendOpenMessage(payload))
        .then((res) => {
          console.log("Reply ::: Open Thunks", res);
          set_texts([]);
          set_units([]);
          set_ship(false);

          dispatch(moveToHistory());
          dispatch(setCurrent({ current: res.payload.data }));
          dispatch(setPending({ pending: res.payload.pending }));
        })
        .catch((error) => {
          console.log("SendOpenMessage Thunk Error: ", error);
        });
    }
  }, [ship, ctyping]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handle_visibility_change);
    return () => {
      window.removeEventListener("visibilitychange", handle_visibility_change);
    };
  }, [handle_visibility_change]);

  useEffect(() => {
    if (texts.length > 0) {
      const end = new Date();
      end.setSeconds(end.getSeconds() + 1.5);

      if (!ctyping && interval === null) {
        function inner_interval(ctyping, interval, set_ship) {
          const now = new Date().getTime();
          const distance = end - now;
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // if (env !== 'production') {
          //   document.getElementById('hubert-responds-time-left').innerHTML = seconds;
          //   document.getElementById('hubert-responds-container').classList.remove('d-none');
          // }

          if (ctyping && interval.interval) {
            clearInterval(x);
            interval.set_interval(null);
            // if (env !== 'production') {
            //   document.getElementById('hubert-responds-time-left').innerHTML = '';
            //   document.getElementById('hubert-responds-container').classList.add('d-none');
            // }
            set_ship(false);
            return;
          } else if (!ctyping && distance < 0) {
            clearInterval(x);
            interval.set_interval(null);
            // if (env !== 'production') {
            //   document.getElementById('hubert-responds-time-left').innerHTML = '';
            //   document.getElementById('hubert-responds-container').classList.add('d-none');
            // }
            set_ship(true);
            return;
          }
        }
        const x = setInterval(function () {
          inner_interval(ctyping, { interval, set_interval }, set_ship);
        }, 100);
        set_interval(x);
      }
    }
  }, [ctyping]);

  const setTextareaHeight = (e) => {
    if (parseInt(e.target.scrollHeight) > 48)
      e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const setDefaultTextareaHeight = () => {
    ref.current.style.height = "48px";
  };

  return (
    <>
      <textarea
        className="input-textarea"
        id="inputText"
        placeholder={write_something(Interview._state.language)}
        ref={ref}
        disabled={is_disabled}
        rows={1}
        onKeyDown={(e) => {
          onKeyDown(e, dispatch, question.id);
        }}
        onInput={(e) => {
          onInput(e, dispatch, question.id);
        }}
        onChange={(e) => {
          setTextareaHeight(e);
          onChange(e, dispatch, set_current_text);
        }}
        onKeyUp={(e) => {
          onKeyUp(e, set_current_text, function () {
            setDefaultTextareaHeight();
            dispatch(toggleCandidateTyping({ value: false }));
            const candidate_answer = {
              sender: "candidate",
              answers: [`${current_text}`],
              wait: 0,
            };

            let metrics = Object.assign({}, qmetrics);
            metrics.current = current_text;
            metrics.end = new Date().getTime();
            set_texts([...texts, current_text]);
            set_units([...units, metrics]);
            set_current_text("");
            set_ship(false);

            dispatch(Reset({ qutter_id: question.id }));
            dispatch(moveToHistory());
            dispatch(nextMessage({ next: candidate_answer }));
          });
        }}
        onPaste={(e) => {
          const text = e.clipboardData.getData("Text");
          dispatch(
            Pastes({
              qutter_id: question.id,
              text,
            })
          );
        }}
        // defaultValue={text}
        value={current_text}
      />
      <div
        onClick={(e) => {
          if (current_text) {
            setDefaultTextareaHeight();
            dispatch(toggleCandidateTyping({ value: false }));
            const candidate_answer = {
              sender: "candidate",
              answers: [`${current_text}`],
              wait: 0,
            };

            let metrics = Object.assign({}, qmetrics);
            metrics.current = current_text;
            metrics.end = new Date().getTime();
            set_texts([...texts, current_text]);
            set_units([...units, metrics]);
            set_current_text("");
            set_ship(false);

            dispatch(Reset({ qutter_id: question.id }));
            dispatch(moveToHistory());
            dispatch(nextMessage({ next: candidate_answer }));
            // send();
          }
        }}
        // className="sendButton"
        className="send-button"
      >
        {/* <div className='center-screen'> */}
        <Send />
        {/* </div> */}
      </div>
    </>
  );
};

export default Input;

/**

.focusin(function () {
  jQuery('#chatContainer').scrollTop(jQuery('#chatContainer')[0].scrollHeight);

  let qmetrics = h.qmetrics;
  qmetrics.got_focus = true;
  if (jQuery('#inputText').val().length > 0) qmetrics.types = true;
  else qmetrics.types = false;
  if (!qmetrics.start) qmetrics.start = new Date().getTime();

  var is_mobile =
    navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i) != null;
  if (is_mobile) jQuery('#notisIcon').toggleClass('hidden');
  //h.update_activity();
})
.focusout(function () {
  let qmetrics = h.qmetrics;
  qmetrics.types = false;

  if (jQuery('#inputText').val().length > 0) qmetrics.types = true;
  else qmetrics.types = false;

  qmetrics.got_focus = false;
  var is_mobile =
    navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i) != null;
  if (is_mobile) jQuery('#notisIcon').toggleClass('hidden');
});

*/
