import { moveToHistory, nextMessage } from "src/store/slices/MessageSlice";

export const chat_matrix_message = (dispatch, form, value, payload) => {
  const row_values = value.row_values;
  let answer = '';
  let text = '';
  let data = [];

  for (let row_id in row_values) {
    const row = form.rows.find(row => row.id === row_id);
    const column_id = row_values[row_id].column_id;
    const column = form.columns.find(column => column.id === column_id);
    const column_idx = form.columns.findIndex(column => column.id === column_id);
    
    if (!column.label.length) {
      answer += `<strong>${row.label}</strong>: ${column_idx + 1} \n <br/>`;
      text = `${row.label}: ${column_idx + 1}`;
    } else {
      answer += `<strong>${row.label}</strong>: ${column.label} \n <br/>`;
      text = `${row.label}: ${column.label}`;
    }

    const d = {
      ...payload,
      text: text,
      row_id: row_id,
      column_id: column_id,
      checked: column_id,
      updated_at: new Date(),
      form_id: form.id,
      form_type: form.type,
    };

    data.push(d);
  }
  const candidate_answer = {
    sender: 'candidate',
    answers: [answer]
  };
  dispatch(moveToHistory());
  dispatch(nextMessage({ next: candidate_answer }));
  return data;
};

export const validate_matrix = (forms, idx, form, value) => {

  if (!form.is_mandatory) {
    form.has_error = false;
    form.error_message = '';
    forms[idx] = form;
    return true;
  }

  if (!value || (!value.row_values)) {
    form.has_error = true;
    form.error_message = 'This field is mandatory.';
    forms[idx] = form;
    return false;
  } 

  // Check all rows are marked
  const row_values = value.row_values;
  let checked = [];
  for (let i = 0; i < form.rows.length; i++) {
    const row = form.rows[i];
    if (row_values.hasOwnProperty(row.id) && row_values[row.id].column_id)
      checked.push(true);
    else checked.push(false);
  }

  if (checked.includes(false)) {
    form.has_error = true;
    form.error_message = 'All rows must be filled in.';
    forms[idx] = form;
    return false;
  }
  else {
    form.has_error = false;
    form.error_message = '';
    forms[idx] = form;
    return true;
  }
};
