import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import CloseEnded from "./CloseEnded";

import { toggleRendering } from "../../../store/slices/MessageSlice";
import SimulateMessage from "../SimulateMessage";

const HubertMessage = ({ wait, message }) => {
  const dispatch = useDispatch();
  const answers = useSelector((state) => state.Messages.answers.list);
  let isTyping = useSelector((state) => state.Messages.htyping);
  let t = 0;
  const delay = 400;

  useEffect(() => {
    dispatch(toggleRendering({ value: true }));
  }, [dispatch]);

  if (!answers) return <span>No answers</span>;

  return (
    <>
      <div className="current-message-wrapper">
        {!isTyping && <div className="hubert-avatar">H</div>}
        <div className="">
          {answers.map((answer, idx) => {
            t += delay;
            return (
              <SimulateMessage
                key={`hubert-message-${idx}-answer-idx-${idx}`}
                wait={wait === 0 ? 0 : t + wait + delay}
                sender="hubert"
                message={message}
                bubble={answer}
                is_last_message={idx === answers.length - 1}
                render_last
                messageType="current"
              />
            );
          })}
        </div>
      </div>
      <CloseEnded message={message} />
    </>
  );
};

export default HubertMessage;
