import {
  forwardRef,
  ComponentProps,
  FormEvent,
  useEffect,
  useRef,
} from "react";
import clsx from "clsx";
import { Send } from "react-feather";
import { Text } from "src/components/common";
import useScrollToComponent from "src/hooks/useScrollToComponent";

type FormContainerProps = ComponentProps<"form"> & {
  forms: any[];
  submitBtnText?: string;
};

const FormContainer = ({
  forms,
  children,
  onSubmit,
  submitBtnText,
}: FormContainerProps) => {
  const messageRef = useRef(null);
  const scrollToMessage = useScrollToComponent(messageRef);

  useEffect(() => {
    if (forms) {
      scrollToMessage();
    }
  }, [scrollToMessage, forms]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit?.(e);
  };

  return (
    <form
      className={clsx(
        "form-container",
        forms.length && forms[0].type === "matrix" && "!max-w-full"
      )}
      onSubmit={handleSubmit}
    >
      {children}
      <div ref={messageRef}>
        <button id="formButton" className="send-button" type="submit">
          <Text>{submitBtnText ?? "Send"}</Text>
          <Send size={16} />
        </button>
      </div>
    </form>
  );
};

export default FormContainer;
