import { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleRendering } from "src/store/slices/MessageSlice";
import { SendReusables } from "src/store/thunks/ReusableThunk";

import {
  nextMessage,
  moveToHistory,
  setCurrent,
  toggleHubertTyping,
} from "src/store/slices/MessageSlice";

import { unset } from "src/store/slices/ReusableSlice";
import "./SaveReusables.scss";

import { readyAndSend } from "./constants";

const SaveReusables = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const Interview = useSelector((state) => state.Interview);
  const order = useSelector((state) => state.Reusables.order);
  const map = useSelector((state) => state.Reusables.map);
  const qmetrics = useSelector((state) => state.Qmetrics);
  const question = useSelector((state) => state.Messages.current);

  const onDoneClick = () => {
    const ret = {
      interview_id: Interview._id,
      answers: [],
    };

    for (let i = 0; i < order.length; i++) {
      const id = order[i];
      const reusable = map[id];

      const not_applicable =
        reusable.hasOwnProperty("applicable") && reusable.applicable === false;
      if (not_applicable) continue;

      const question = {
        sender: "hubert",
        answers: [`${reusable.question.text}`],
      };
      dispatch(moveToHistory());
      dispatch(nextMessage({ next: question }));

      if (reusable.question.type === "open") {
        const candidate_answer = {
          sender: "candidate",
          answers: [`${reusable.texts}`],
        };
        dispatch(moveToHistory());
        dispatch(nextMessage({ next: candidate_answer }));

        ret.answers.push({
          interview_id: Interview._id,
          reused_interview_id: reusable.interview_id,
          qutter_oid: reusable.qutter_oid,
          qutter_id: reusable.qutter_id,
          language: reusable.question.language,
          type: reusable.question.type,

          value: reusable.texts,
          text: reusable.texts,
          messages: reusable.texts,

          texts: reusable.texts,
          units: [qmetrics[id]],

          reusage: reusable.reusage,
        });
      } else {
        const candidate_answer = {
          sender: "candidate",
          answers: [`${reusable.label}`],
        };
        dispatch(moveToHistory());
        dispatch(nextMessage({ next: candidate_answer }));

        ret.answers.push({
          interview_id: Interview._id,
          reused_interview_id: reusable.interview_id,
          qutter_oid: reusable.qutter_oid,
          qutter_id: reusable.qutter_id,
          language: reusable.question.language,
          type: reusable.question.type,

          value: reusable.value,
          text: reusable.label,
          label: reusable.label,

          reusage: reusable.reusage,
        });
      }
    }
    // Unset the reusables
    dispatch(unset());

    dispatch(toggleHubertTyping({ value: true }));

    dispatch(SendReusables(ret))
      .then((res) => {
        dispatch(toggleHubertTyping({ value: false }));

        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());
        dispatch(setCurrent({ current: res.payload.data }));
      })
      .catch((res) => {
        console.log("SendMessage Thunk Error: ", res);
      });
  };

  return (
    <button
      ref={ref}
      className="btn w-full save-reuse-button"
      data-value={`${"reusable-done"}`}
      onClick={onDoneClick}
      dangerouslySetInnerHTML={{
        __html: readyAndSend[question.language],
      }}
    ></button>
  );
});

export default SaveReusables;
