


export const readyAndSend = {
  'sv':     'Klar och skicka',
  'sv-se':  'Klar och skicka',
  'en':     'Ready and send',
  'en-gb':  'Ready and send',
  'en-us':  'Ready and send',
  'de-de':  'Fertig und abschicken',
  'da-dk':  'Færdig og send',
  'no-no':  'Ferdig og send',
  'fi-fi':  'Valmis ja lähetä',
  'fr-fr':  'Fait et envoyé',
  'es-es':  'Listo y enviar',
  'es-mx':  'Listo y enviar',
}

export const companyString = {
  'sv':     'Företag/organisation:',
  'sv-se':  'Företag/organisation:',
  'en':     'Company/organization:',
  'en-gb':  'Company/organization:',
  'en-us':  'Company/organization:',
  'de-de':  'Firma/Organisation:',
  'da-dk':  'Virksomhed/organisation:',
  'no-no':  'Bedrift/organisasjon:',
  'fi-fi':  'Yritys/organisaatio:',
  'fr-fr':  'Entreprise/organisation:',
  'es-es':  'Empresa/organización:',
  'es-mx':  'Empresa/organización:',
};

export const reuseDateString = {
  'sv':     'Återanvändning av svar från intervjun tagen',
  'sv-se':  'Återanvändning av svar från intervjun tagen',
  'en':     'Reuse of answers from the interview taken',
  'en-gb':  'Reuse of answers from the interview taken',
  'en-us':  'Reuse of answers from the interview taken',
  'de-de':  'Wiederverwendung von Antworten aus dem Interview',
  'da-dk':  'Genbrug af svar fra interviewet',
  'no-no':  'Gjenbruk av svar fra intervjuet tatt',
  'fi-fi':  'Haastattelun vastausten uudelleenkäyttö',
  'fr-fr':  "Réutilisation des réponses de l'interview prise",
  'es-es':  'Reutilización de respuestas de la entrevista realizada',
  'es-mx':  'Reutilización de respuestas de la entrevista realizada',
};

export const position = {
  'sv':     'Position:',
  'sv-se':  'Position:',
  'en':     'Role:',
  'en-gb':  'Role:',
  'en-us':  'Role:',
  'de-de':  'Rolle:',
  'da-dk':  'Rolle:',
  'no-no':  'Rolle:',
  'fi-fi':  'Rooli:',
  'fr-fr':  "Rôle:",
  'es-es':  'Role:',
  'es-mx':  'Role:',
};
