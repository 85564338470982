import { HubertDatePicker } from "./Calendar";
import { FillInTheGaps } from "./FillInTheGaps";
import { Forms } from "./Form";
import { Info } from "./Info";
import { MultipleSelect } from "./MultipleSelect";
import { Open } from "./Open";
import { ReusablesContainer } from "./Reusables";
import { SingleSelect } from "./SingleSelect";
import { Slider } from "./Slider";
import Calendly from "./Calendly";

const Prompts = {
  HubertDatePicker,
  FillInTheGaps,
  Forms,
  Info,
  MultipleSelect,
  Open,
  ReusablesContainer,
  SingleSelect,
  Slider,
  Calendly,
};

export default Prompts;
