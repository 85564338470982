import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  status: 'idle'
};

const JobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    initJob (state, action) {
      const { job } = action.payload;
      return {
        ...state,
        ...job
      };
    }
  }
});

export const {
  initJob
} = JobSlice.actions;
export default JobSlice.reducer;
