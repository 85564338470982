import ReusablesCompany from "./ReusablesCompany";
import ReusablesDate from "./ReusablesDate";
import ReusablesRoleName from "./ReusablesRoleName";

const ReusablesHeader = () => {
  return (
    <header className="reusables-header">
      <ReusablesDate />
      <ReusablesCompany />
      <ReusablesRoleName />
    </header>
  );
};

export default ReusablesHeader;
