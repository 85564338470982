import React, { useState, useEffect, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import ReusableInput from "./ReusableInput";
import { ConditionedOn } from "../ConditionedOn";
import { SetQmetrics } from "src/store/slices/QmetricSlice";

const ReusableOpen = forwardRef(({ reusable }, ref) => {
  const dispatch = useDispatch();
  const [edit, set_edit] = useState(false);
  const [show, set_show] = useState(true);

  useEffect(() => {
    if (!reusable) return;
    dispatch(
      SetQmetrics({
        qutter_id: reusable.qutter_id,
        qmetrics: reusable.qmetrics,
      })
    );
  }, []);

  const date = reusable.created_at ? reusable.created_at : new Date();

  return (
    <>
      <ConditionedOn reusable={reusable} set_show={set_show} />
      <div className={`reusable-open ${show ? "" : "d-none"}`}>
        <div className="question-wrapper">
          <i>
            <p
              className="m-0"
              dangerouslySetInnerHTML={{ __html: reusable.question.text }}
            ></p>
          </i>
        </div>

        {edit ? (
          <ReusableInput
            ref={ref}
            reusable={reusable}
            edit={{ edit, set_edit }}
          />
        ) : (
          <div ref={ref} className="answer-wrapper">
            <div className="answer-text">{reusable.texts}</div>
            <div className="edit-button-wrapper">
              <div className="edit-button" onClick={() => set_edit(!edit)}>
                <FontAwesomeIcon
                  icon={light("pen-to-square")}
                  size="lg"
                  className="edit-icon"
                />
                <span>Edit Answer</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
});

ReusableOpen.displayName = "ReusableOpen";

export default ReusableOpen;
