import React from 'react';

export const FormError = ({
  has_error,
  error_message
}) => {
  return (
    <div
      className={`text-center w-100 ${has_error ? '': 'd-none'}`}
      style={{ minHeight: '18px' }}
    >
      <p id="input_error" className={'m-0 ' + has_error ? '':'hidden'} style={{ color: 'red' }}>
        {error_message}
      </p>
    </div>
  );
}
