import SimulateMessage from "src/components/Messages/SimulateMessage";

const CandidateHistoryMessage = ({ wait, message }) => {
  if (Array.isArray(message.list)) {
    return message.list.map((answer, idx) => (
      <SimulateMessage
        key={`candidate-message-${idx}-answer-idx-${idx}`}
        wait={wait}
        sender="candidate"
        message={message}
        bubble={answer}
      />
    ));
  }

  return (
    <SimulateMessage
      wait={wait}
      sender="candidate"
      message={message}
      bubble={message.list}
    />
  );
};

export default CandidateHistoryMessage;
