import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

const CandidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    initCandidate(state, action) {
      const { candidate } = action.payload;
      return {
        ...state,
        ...candidate,
      };
    },
    updateCandidate(state, action) {
      const { _id, first_name, last_name, email } = action.payload;
      return {
        ...state,
        _id,
        first_name,
        last_name,
        email,
      };
    },
  },
});

export const { initCandidate, updateCandidate } = CandidateSlice.actions;
export default CandidateSlice.reducer;
