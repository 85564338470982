import { useState, useEffect, ComponentProps } from "react";
import { useAppSelector } from "src/store/hooks";
import "./ProgressBar.scss";

interface ProgressBarProps extends ComponentProps<"div"> {
  interviewLength: number;
}

const ProgressBar = ({ interviewLength }: ProgressBarProps) => {
  const [width, setWidth] = useState(0);
  const pending = useAppSelector((state) => state.Messages.pending);

  useEffect(() => {
    if (pending === null) return;

    if (interviewLength) {
      const questionRangeValue = 100 / interviewLength;
      const percentage = questionRangeValue * (interviewLength - pending);
      if (percentage > width) setWidth(Math.trunc(percentage));
    }
  }, [pending, interviewLength, width]);

  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar">
        <div className="filled" style={{ width: `${width}%` }} />
      </div>
      {/* <Text weight="semibold" size="small">
        {`${width}%`}
      </Text> */}
    </div>
  );
};

export default ProgressBar;
