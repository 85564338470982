import { useOutletContext, useSearchParams } from "react-router-dom";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import DebugActionBar from "src/components/Testing/DebugActionBar";
import { Header } from "src/components/Header";
import MessageHistory from "src/components/MessageHistory";
import MessageTimeProvider from "src/components/Messages/MessageTimeProvider/MessageTimeProvider";

export const PreviewInterview = () => {
  const [template, candidate, application, job, interview] = useOutletContext();
  const [searchParams] = useSearchParams();

  return job && template && interview && candidate && application ? (
    <section className="container" style={{ padding: "2rem" }}>
      {/* <div className="row h-100"> */}
      {searchParams.get("debug") === "true" && <DebugActionBar />}

      <div id="jarvisContainer" className="jarvis interview-wrapper">
        <div
          id="bubbleContainer"
          className="bubbleChatBox page interview-inner-wrapper"
        >
          {/* Header */}
          <Header job={job} template={template} />

          {/* Chat */}
          <div id="j-chat" className="j-chat">
            <div
              id="chatContainer"
              className="chatContainer w-100 j-inner chat-container-wrapper"
            >
              <MessageTimeProvider>
                <MessageHistory />
                {/* <CurrentMessage /> */}
              </MessageTimeProvider>
            </div>

            {/* <Typing /> */}
          </div>

          {/* Input */}
          {/* <InputArea /> */}
        </div>
      </div>
      {/* </div> */}
    </section>
  ) : (
    // TODO: Real loading screen
    <LoadingScreen />
  );
};
