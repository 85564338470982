import React, { useState } from "react";
import { FormLabel, FormError } from "../..";
import Slider from "rc-slider";
import { Text } from "src/components/common";
import "rc-slider/assets/index.css";
import "./FormSlider.scss";

export const FormSlider = ({ form, values, set_values }) => {
  const [value, set_value] = useState(
    values && values.hasOwnProperty(form.id) ? values[form.id].value : 0
  );
  const min = form.min ? form.min : 0;
  const max = form.max ? form.max : 100;
  const step = form.step ? form.step : (max - min) / 100;

  const on_change = (value) => {
    const data = {
      id: form.id,
      value: value,
      updated_at: new Date(),
      is_mandatory: form.is_mandatory,
      hubiverse_property: form.hubiverse_property,
      candidate_property: form.candidate_property,
    };
    set_values({
      ...values,
      [form.id]: data,
    });
    set_value(value);
  };

  return (
    <div className="form-group form-slider">
      <FormLabel label={form.label} is_mandatory={form.is_mandatory} />
      <FormError
        has_error={form.has_error}
        error_message={form.error_message}
      />

      <Text block weight="semibold" className="unit-label">
        {value} {form.unit}
      </Text>

      <Slider
        className="custom-rc-slider"
        min={min}
        max={max}
        step={step}
        defaultValue={value}
        // value={value}
        // dots={true}
        onChange={on_change}
        // marks={buildMarks(min, max, step)}
      />

      <div className="range-labels">
        <Text block weight="semibold" className="label-left">
          {form.label_left}
        </Text>
        <Text block weight="semibold" className="label-right">
          {form.label_right}
        </Text>
      </div>
    </div>
  );
};
