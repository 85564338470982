import { createSlice } from '@reduxjs/toolkit';
const initialState = {};

const ApplicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    initApplication (state, action) {
      const { application } = action.payload;
      return {
        ...state,
        ...application
      };
    }
  }
});

export const {
  initApplication
} = ApplicationSlice.actions;
export default ApplicationSlice.reducer;
