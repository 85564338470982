import React, { useState } from "react";
import { Check } from "react-feather";
import { Text } from "src/components/common";

const MultipleSelectElement = ({ options, value, onChange }) => {
  const [selectedValues, setSelectedValues] = useState(value || []);

  //Actual options, not like select-all etc.
  const selectableOptions = options.filter((o) => o.value === undefined);

  const handleChange = (option) => {
    const isSelected = selectedValues.some((o) => {
      return option.label === o.label;
    });

    let selectedOptionsCount = selectedValues.filter(
      (o) => o.value === undefined
    ).length;

    if (option.value === undefined) selectedOptionsCount++;

    if (option.value === "none-above") {
      // None - Deselect all
      const newValue = [option];
      setSelectedValues(newValue);
      onChange(newValue, { action: null, option: option });
    } else if (option.value === "other") {
      let newValue;
      if (isSelected) {
        newValue = selectedValues.filter((o) => o.value !== "other");
      } else {
        newValue = [
          option,
          ...selectedValues.filter((o) => o.value !== "none-above"),
        ];
      }
      setSelectedValues(newValue);
      onChange(newValue, { action: null, option: option });
    } else if (option.value === "all-above") {
      const newValue = options.filter(
        (o) => o.value !== "none-above" && o.value !== "other"
      );

      setSelectedValues(newValue);
      onChange(newValue, { action: null, option: option });
    } else if (option.value === undefined && isSelected) {
      //If option already is selected

      const newValue =
        selectedValues.filter(
          (o) => o.label !== option.label && o.value !== "all-above"
        ) || [];

      setSelectedValues(newValue);
      onChange(newValue, { action: null, option: option });
    } else if (
      option.value === undefined &&
      selectedOptionsCount === selectableOptions.length
    ) {
      const newValue = options.filter(
        (o) => o.value !== "none-above" && o.value !== "other"
      );
      setSelectedValues(newValue);
      onChange(newValue, { action: null, option: option });
    } else {
      //Select option not already selected.
      const newValue = [
        option,
        ...selectedValues.filter((o) => o.value !== "none-above"),
      ];
      setSelectedValues(newValue);
      onChange(newValue, { action: null, option: option });
    }
  };

  return (
    <>
      {options.map((o, id) => {
        const isSelected = selectedValues.some((option) => {
          return option.label === o.label;
        });

        return (
          <div key={id} className="select-bubble-wrapper">
            <div
              className={`select-option ${isSelected && "selected"}`}
              onClick={() => {
                handleChange(o);
              }}
            >
              <Text>{o.label}</Text>
              {isSelected && <Check size={16} />}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MultipleSelectElement;
