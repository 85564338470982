import { useSelector } from "react-redux";
import "./Typing.scss";
import clsx from "clsx";

const TypingIndicator = ({ className }) => {
  const htyping = useSelector((state) => state.Messages.htyping);

  return (
    <div id="hubert-typing" className={clsx(className, "hubert-typing")}>
      <div className="hubert-avatar">H</div>
      <div className="chat-bubble-element-wrapper typing">
        <div
          id="hubert-typing-inner"
          className={`chat-bubble-element left ${!htyping ? "hidden" : ""}`}
        >
          <span className="circle scaling"></span>
          <span className="circle scaling"></span>
          <span className="circle scaling"></span>
        </div>
      </div>
    </div>
  );
};

export default TypingIndicator;
