import clsx from "clsx";
import { ComponentProps } from "react";
import { useMediaQuery } from "react-responsive";
import ProgressBar from "src/components/common/ProgressBar/ProgressBar";
import Text from "src/components/common/Text";
import { useAppSelector } from "src/store/hooks";
import { Breakpoints } from "src/utils/breakpoints";
import "./Header.scss";

interface HeaderProps extends ComponentProps<"header"> {
  interviewLength: number;
}

export const Header = ({
  interviewLength,
  className,
  ...headerProps
}: HeaderProps) => {
  const jobDetails: any = useAppSelector((state) => state.Job);
  const isDesktop = useMediaQuery({
    minWidth: Breakpoints.lg,
  });

  return (
    <header {...headerProps} className={clsx(className, "chat-header-wrapper")}>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <Text weight="semibold" className="letter">
            H
          </Text>
        </div>
        <div className="title-wrapper">
          <Text weight="semibold" block>
            {!isDesktop ? jobDetails.company : "Hubert"}
          </Text>
          <Text size="small" className="online" block>
            {!isDesktop ? jobDetails.title : "Online"}
          </Text>
        </div>
      </div>
      <ProgressBar interviewLength={interviewLength} />
    </header>
  );
};
