import { useSelector } from "react-redux";

import { position } from "../constants";

const ReusablesRoleName = () => {
  const roleName = useSelector((state) => state.Reusables.role_name);
  const question = useSelector(state => state.Messages.current);
  if (!roleName) return null;

  return (
    <p className='d-block'>
      <span>{position[question.language]} </span>
      <span>{roleName}</span>
    </p>
  );
};

export default ReusablesRoleName;
