import { useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Prompts from "src/components/Prompts";
import useScrollToComponent from "src/hooks/useScrollToComponent";

const CloseEnded = ({ message_idx }) => {
  const message = useSelector((state) => state.Messages.current);
  const rendering = useSelector((state) => state.Messages.is_rendering);
  const messagesEndRef = useRef(null);
  const scrollToMessage = useScrollToComponent(messagesEndRef);

  useEffect(() => {
    if (!rendering) {
      if (message.type === "calendly") {
        const scrollTimeout = setTimeout(() => {
          scrollToMessage();
        }, [1000]);

        return () => {
          clearTimeout(scrollTimeout);
        };
      }

      scrollToMessage();
    }
  }, [scrollToMessage, rendering, message?.type]);

  const question = useMemo(() => {
    if (rendering) {
      return null;
    }

    if (!message) {
      return <span>ERROR: No message.</span>;
    }

    if (!message.type || !message.type.length) {
      return <span>ERROR: No message type.</span>;
    }

    switch (message.type) {
      case "info":
        return (
          <Prompts.Info
            ref={messagesEndRef}
            message={message}
            message_idx={message_idx}
          />
        );

      case "open": {
        document.getElementById(`${"j-chat"}`)?.classList.remove("full");
        return (
          <Prompts.Open
            ref={messagesEndRef}
            message={message}
            message_idx={message_idx}
          />
        );
      }

      case "single-select":
        return (
          <Prompts.SingleSelect
            ref={messagesEndRef}
            message={message}
            message_idx={message_idx}
          />
        );

      case "multiple-select":
        return (
          <Prompts.MultipleSelect
            ref={messagesEndRef}
            message={message}
            message_idx={message_idx}
          />
        );

      case "date-picker":
        return (
          <Prompts.HubertDatePicker
            ref={messagesEndRef}
            message={message}
            message_idx={message_idx}
          />
        );

      case "slider":
        return (
          <Prompts.Slider
            ref={messagesEndRef}
            message={message}
            message_idx={message_idx}
          />
        );

      case "reusable":
        return (
          <Prompts.ReusablesContainer
            ref={messagesEndRef}
            message={message}
            message_idx={message_idx}
          />
        );

      case "form":
        return <Prompts.Forms message={message} message_idx={message_idx} />;

      case "gaps":
        return (
          <Prompts.FillInTheGaps
            ref={messagesEndRef}
            message={message}
            message_idx={message_idx}
          />
        );

      case "calendly":
        return <Prompts.Calendly ref={messagesEndRef} message={message} />;

      default:
        return null;
    }
  }, [message, message_idx, rendering]);

  return question;
};

export default CloseEnded;
