import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const QmetricSlice = createSlice({
  name: 'qmetrics',
  initialState,
  reducers: {
    Test (state, action) {
      console.log('TEST');
    },
    PushKey (state, action) {
      const { qutter_id, keycode } = action.payload;
      const qmetric = state[qutter_id];
      qmetric.keys.push(keycode);
    },
    PushChange (state, action) {
      const { qutter_id, value } = action.payload;
      const qmetric = state[qutter_id];
      const now = new Date();
      const change = {
        text: value,
        timestamp: now.getTime(),
        key: qmetric.current_key
      };
      qmetric.changes.push(change);
      qmetric.current = value;
    },
    SetCurrentKey (state, action) {
      const { qutter_id, keycode } = action.payload;
      const qmetric = state[qutter_id];
      qmetric.current_key = keycode;
    },
    SetEndTime (state, action) {
      const { qutter_id, end } = action.payload;
      const qmetric = state[qutter_id];
      qmetric.end = end;
    },
    PushBackspace (state, action) {
      const { qutter_id } = action.payload;
      const qmetric = state[qutter_id];
      qmetric.backspaces++;
    },
    VisibilityChange (state, action) {
      const { qutter_id, visibility_change } = action.payload;
      const qmetric = state[qutter_id];
      qmetric.tab_switches.push(visibility_change);
    },
    Pastes (state, action) {
      const { qutter_id, text } = action.payload;
      const now = new Date();
      const qmetric = state[qutter_id];
      const paste = { timestamp: now.toUTCString(), text };

      if (!qmetric.hasOwnProperty('pastes'))
        qmetric.pastes = { n: 0, texts: [] };
      qmetric.pastes.n += 1;
      qmetric.pastes.texts.push(paste);

      if (!qmetric.hasOwnProperty('paste'))
        qmetric.paste = [];
      qmetric.paste.push(paste);
    },
    AddQmetrics (state, action) {
      const { qutter_id } = action.payload;
      const now = new Date();
      return {
        ...state,
        [qutter_id]: {
          current_key: null,
          current: null,
          keys: [],
          changes: [],
          start:  now.getTime(),
          end: null,
          backspaces: 0,
          pastes: {
            n:      0,
            texts:  []
          },
          paste: [],
          tab_switches: [],
        }
      }
    },
    SetQmetrics (state, action) {
      const { qutter_id, qmetrics } = action.payload;
      return {
        ...state,
        [qutter_id]: qmetrics
      }
    },
    Reset (state, action) {
      const { qutter_id } = action.payload;
      const now = new Date();
      return {
        ...state,
        [qutter_id]: {
          current:      '',
          current_key:  null,
          keys:         [],
          changes:      [],
          start:        now.getTime(),
          end:          null,
          backspaces:   0,
          pastes:       {
            n:      0,
            texts:  []
          },
          paste:        [],
          tab_switches: [],
          // got_focus: false,
          // types: false,
          // prev: '',
        }
      }
    }
  }
});

export const {
  Test,
  AddQmetrics,
  SetQmetrics,
  Reset,

  PushKey,
  SetCurrentKey,
  PushBackspace,
  VisibilityChange,
  Pastes,
  PushChange,
  SetEndTime
} = QmetricSlice.actions;
export default QmetricSlice.reducer;
