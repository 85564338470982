
export function PushMessage (state, action) {
  const { next } = action.payload;
  state.list = state.list.concat(next);
};

export function NextMessage (state, action) {
  const { next } = action.payload;
  state.answers = {
    sender: next.sender,
    list: next.answers,
    wait: next.wait !== undefined ? next.wait: undefined
  };
  // state.next = next;
};

export function SetCurrent (state, action) {
  const { current } = action.payload;
  state.answers = {
    sender: current.sender,
    list: current.answers
  };
  // state.next = current;
  state.current = current;
};

export function SetPending (state, action) {
  const { pending } = action.payload;
  state.pending = pending;
};

export function MoveToHistory (state, action) {
  // state.history.push(state.next);
  state.history.push(state.answers);
  state.answers = null;
}; 

export function PushToHistory (state, action) {
  const { history } = action.payload;
  state.history = state.history.concat(history);
};

export function ToggleCandidateTyping (state, action) {
  try {
    const { value } = action.payload;
    return {
      ...state,
      ctyping: value
    };
  } catch (error) {
    return {
      ...state,
      ctyping: !state.ctyping
    }
  }
};

export function ToggleHubertTyping (state, action) {
  try {
    const { value } = action.payload;
    return {
      ...state,
      htyping: value
    };
  } catch (error) {
    return {
      ...state,
      htyping: !state.htyping
    }
  }
};

export function ToggleRendering (state, action) {
  try {
    const { value } = action.payload;
    return {
      ...state,
      is_rendering: value
    };
  } catch (error) {
    return {
      ...state,
      is_rendering: !state.is_rendering
    };
  }
};

export function RemoveOptions (state, action) {
  delete state.current.options;
};

export function SetSelectedOption (state, action) {
  const { option_id, option_idx, selected } = action.payload;
  try {
    let updated = state.current.options.map((option, idx) => {
      if (idx !== option_idx) {
        // This isn't the item we clicked
        if (option_id === 'all-above') {
          return {
            ...option,
            selected: false
          };
        }
        if (option_id === 'none-above') {
          return {
            ...option,
            selected: false
          }
        } else {
          return option;
        }
      }
      // Otherwise, this is the one we want - return an updated value
      return {
        ...option,
        selected: selected
      }
    });
    state.current.options = updated;
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};