import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Form from "react-bootstrap/Form";

import {
  moveToHistory,
  nextMessage,
  setCurrent,
  toggleRendering,
} from "src/store/slices/MessageSlice";

const base = process.env.REACT_APP_URL
  ? process.env.REACT_APP_URL
  : "http://localhost:4001";
const URL = `${base}/interview/v1/reply`;

export const Slider = forwardRef(({ id }, ref) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());

  const Interview = useSelector((state) => state.Interview);
  let message = useSelector((state) => state.Messages.current);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    document.getElementById(`${"j-chat"}`).classList.add("full");
  }, []);

  const send = () => {
    if (!date) return setErrorMessage("You must do something.");
    else setErrorMessage("");

    const candidate_answer = {
      sender: "candidate",
      answers: [`${message.candidate_answer_pre_text}: ${date}`],
    };

    dispatch(moveToHistory());
    dispatch(nextMessage({ next: candidate_answer }));

    axios
      .post(`${URL}`, {
        interview_id: Interview._id,
        _id: message._id,
        qutter_id: message.id,
        language: message.language,
        type: message.type,
        date: date,
      })
      .then((res) => {
        // document.getElementById(`${'j-chat'}`).classList.remove('full');

        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());
        // dispatch(nextMessage({ next: res.data.data }));
        dispatch(setCurrent({ current: res.data.data }));
      })
      .catch((res) => {
        console.log("Axios Error <Slider/>: ", res);
      });
  };

  return (
    <form ref={ref} className="mt-5 mb-3 mx-3 form" id="EktCEj">
      <p style={{ fontWeight: 600 }}>
        Välj det datum du kan påbörja denna tjänst i kalendern nedan.
      </p>

      <div className="text-center">
        <Form.Range />
      </div>

      <div
        id="input_error_container"
        className="text-center w-100"
        style={{ minHeight: "18px" }}
      >
        <p
          id="input_error"
          className={`m-0 ${errorMessage ? errorMessage : "hidden"}`}
          style={{ color: "red" }}
        >
          {errorMessage}
        </p>
      </div>

      <hr />
      <div
        id="formButton"
        className="inputBubbles"
        onClick={() => {
          send();
        }}
      >
        Skicka
      </div>
    </form>
  );
});
