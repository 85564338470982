
const update_text = function (pending, percent_left, language) {
  const zero_pending_map = {
    sv:       `Inga frågor kvar. Bra jobbat!`,
    en:       `No questions left. Good job!`,
    'en-gb':  `No questions left. Good job!`,
    'en-us':  `No questions left. Good job!`,
    de:       `Keine Fragen mehr. Gut gemacht!`,
    es:       `No quedan preguntas. ¡Buen trabajo!`,
  };
  
  const one_pending_map = {
    sv:       `En fråga kvar`,
    en:       `One question left`,
    'en-gb':  `One question left`,
    'en-us':  `One question left`,
    de:       `Eine Frage bleibt`,
    es:       `Queda una pregunta`,
  };
  
  const percent_pending_map = {
    sv:       `${percent_left}% kvar`,
    en:       `${percent_left}% left`,
    'en-gb':  `${percent_left}% left`,
    'en-us':  `${percent_left}% left`,
    de:       `${percent_left}% übrig`,
    es:       `${percent_left}% restante`,
  };

  let pending_text = `${percent_left}% left`;
  if (['sv', 'en','en-us', 'en-gb', 'es', 'de'].includes(language)) {
    if (pending > 0) {
      if (pending === 1) {
        pending_text = one_pending_map[language];
      } else {
        pending_text = percent_pending_map[language];
      }
    } else {
      pending_text = zero_pending_map[language];
    }
  }

  return pending_text;
};

export const update_questions_Left = async function (
  language,
  total,
  percent_prev,
  pending
) {

  let percent_done = (1 - pending / total) * 100;
  if (percent_done < percent_prev) {
    return null;
  }

  let percent_left = parseInt(100 - percent_done);
  let pending_text = update_text(pending, percent_left, language);

  return {
    percent_done,
    pending_text
  }
};