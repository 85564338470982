import axios from "axios";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import {
  ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  EventScheduledEvent,
  InlineWidget,
  useCalendlyEventListener,
} from "react-calendly";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  toggleRendering,
} from "src/store/slices/MessageSlice";
import { SendMessage } from "src/store/thunks/MessageThunk";
import { ScheduledEvent } from "./types";
import { Error, Ok, Result } from "src/utils/Result";
import useMediaQuery from "src/hooks/useMediaQuery";

dayjs.extend(calendar);

type CalendlyEventScheduledData = EventScheduledEvent["data"];

type ScheduledEventResourceOrError = Result<unknown, ScheduledEvent>;

type CalandelyProps = Omit<ComponentProps<"div">, "ref">;

const Calendly = forwardRef<HTMLDivElement, CalandelyProps>((props, ref) => {
  const dispatch = useDispatch();
  const Interview = useSelector<any, any>((state) => state.Interview);
  const question = useSelector<any, any>((state) => state.Messages.current);
  const candidate = useSelector<any, any>((state) => state.Candidate);

  const [eventScheduledEvent, setEventScheduledEvent] =
    useState<CalendlyEventScheduledData | null>(null);
  const [resolvedScheduledEventDetails, setResolvedScheduledEventDetails] =
    useState<ScheduledEventResourceOrError | null>(null);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setEventScheduledEvent(e.data);
    },
  });

  const sendMessage = useCallback(
    (scheduledEventResourceOrError: ScheduledEventResourceOrError) => {
      let formattedStartTime = "";
      let eventName = question[Interview._state.language].plain_text || "Event";

      if (!scheduledEventResourceOrError.isError()) {
        formattedStartTime = ` for ${dayjs(
          scheduledEventResourceOrError.value.start_time
        ).calendar()}`;
        eventName = scheduledEventResourceOrError.value.name;
      }

      const answerText = `${eventName} scheduled${formattedStartTime}.`;

      const candidate_answer = {
        sender: "candidate",
        answers: [answerText],
      };

      // @ts-ignore
      dispatch(moveToHistory());

      // @ts-ignore
      dispatch(nextMessage({ next: candidate_answer }));

      dispatch(
        // @ts-ignore
        SendMessage({
          interview_id: Interview._id,
          candidate_id: Interview.candidate_id,
          job_id: Interview.job_id,
          template_id: Interview.template_id,
          application_id: Interview.application_id,
          qutter_oid: question._id,
          qutter_id: question.id,
          language: question.language,
          type: question.type,

          text: answerText,
          value: answerText,

          calendly: {
            event: eventScheduledEvent,
            resource: scheduledEventResourceOrError.isError()
              ? scheduledEventResourceOrError
              : null,
          },
        })
      )
        // @ts-ignore
        .then((res) => {
          // @ts-ignore
          dispatch(toggleRendering({ value: true }));

          // @ts-ignore
          dispatch(moveToHistory());

          // @ts-ignore
          dispatch(setCurrent({ current: res.payload.data }));
        })
        .catch((res: unknown) => {
          console.log("SendMessage Thunk Error: ", res);
        });
    },
    [dispatch, Interview, question, eventScheduledEvent]
  );

  useEffect(() => {
    const getScheduledEventDetails = async (
      eventScheduledEvent: CalendlyEventScheduledData
    ) => {
      try {
        const bearerToken = `Bearer ${
          question[Interview._state.language].calendlyPersonalAccessToken
        }`;
        const calendlyResponse = await axios.get<{ resource: ScheduledEvent }>(
          eventScheduledEvent.payload.event.uri,
          { headers: { Authorization: bearerToken } }
        );

        setResolvedScheduledEventDetails(
          Ok.with(calendlyResponse.data.resource)
        );
      } catch (error) {
        setResolvedScheduledEventDetails(Error.of(error));
      }
    };

    if (eventScheduledEvent !== null) {
      getScheduledEventDetails(eventScheduledEvent);
    }
  }, [eventScheduledEvent, Interview, question]);

  useEffect(() => {
    if (resolvedScheduledEventDetails !== null) {
      sendMessage(resolvedScheduledEventDetails);
    }
  }, [resolvedScheduledEventDetails, sendMessage]);

  const isMobile = useMediaQuery({ breakpoint: "sm" });

  const calendlyDimensions = useMemo(
    () => ({
      lg: {
        width: "100%",
        height: "920px",
      },
      sm: {
        width: "100%",
        height: "850px",
      },
    }),
    []
  );

  const iframeTitle = "Calendly Scheduling Page";

  return (
    <ErrorBoundary fallback={<div>Something went wrong with Calendly</div>}>
      <div ref={ref} {...props} aria-label={iframeTitle} className="mt-4">
        <InlineWidget
          iframeTitle={iframeTitle}
          url={question[Interview._state.language].calendlyUrl}
          prefill={{
            email: candidate.email,
            // We are including all 3 fields in case there are forms which take name but do not take first name and last name, or the other way around.
            name: [
              ...(candidate.first_name ? [candidate.first_name] : []),
              ...(candidate.last_name ? [candidate.last_name] : []),
            ].join(" "),
            firstName: candidate.first_name || "",
            lastName: candidate.last_name || "",
          }}
          styles={
            isMobile ? calendlyDimensions["sm"] : calendlyDimensions["lg"]
          }
          pageSettings={{
            hideGdprBanner: true,
            hideLandingPageDetails: true,
            hideEventTypeDetails: true,
            primaryColor: "7543ff",
            textColor: "111827",
          }}
        />
      </div>
    </ErrorBoundary>
  );
});

export default Calendly;
