import React from 'react';
import { FormLabel, FormError } from '../..';

export const FormCheckbox = ({
  form,
  values,
  set_values
}) => {

  return (
  <div className="form-group">

    <FormLabel label={form.label} is_mandatory={form.is_mandatory} />
    <FormError has_error={form.has_error} error_message={form.error_message} />

    <input
      type="checkbox"
      placeholder="Data Privacy Agreement" 
      value="" 
      className="consent-checkbox vertical-align-center"
      onChange={(e) => {
        const data = {
          id: form.id,
          checked: e.target.checked,
          updated_at: new Date(),
          is_mandatory: form.is_mandatory,
          hubiverse_property: form.hubiverse_property,
          candidate_property: form.candidate_property,
        };
        set_values({
          ...values,
          [form.id]: data
        })
      }}
    />
  </div>
  );
}
