import clsx from "clsx";
import { ComponentProps } from "react";

type ButtonType = "primary" | "secondary" | "danger" | "tertiary";

interface ButtonProps extends ComponentProps<"button"> {
  variant: ButtonType;
  expanded?: boolean;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
}

const Button = ({
  variant,
  expanded,
  children,
  iconLeft,
  iconRight,
  ...buttonProps
}: ButtonProps) => {
  return (
    <button
      className={clsx(
        "btn",
        variant === "primary" && "btn-primary",
        variant === "secondary" && "btn-secondary",
        variant === "tertiary" && "btn-tertiary",
        variant === "danger" && "btn-danger",
        expanded && "w-full"
      )}
      {...buttonProps}
    >
      {iconLeft && <span>{iconLeft}</span>}
      <span>{children}</span>
      {iconRight && <span>{iconRight}</span>}
    </button>
  );
};

export default Button;
