import { useSelector } from "react-redux";
import { companyString } from "../constants";

const ReusablesCompany = () => {
  const company = useSelector((state) => state.Reusables.company);
  const question = useSelector(state => state.Messages.current);
  if (!company) return null;
  return (
    <p>
      <span>{companyString[question.language]} </span>
      <span>{company}</span>
    </p>
  );
};

export default ReusablesCompany;
