import { moveToHistory, nextMessage } from "src/store/slices/MessageSlice";

const checked = {
  sv: '👍',
  no: '👍',
  dk: '👍',
  fi: '👍',
  en: '👍',
  'en-us': '👍',
  'en-gb': '👍',
  es: '👍',
  de: '👍',
  fr: '👍'
};

const not_checked = {
  sv: '👎',
  no: '👎',
  dk: '👎',
  fi: '👎',
  en: '👎',
  'en-us': '👎',
  'en-gb': '👎',
  es: '👎',
  de: '👎',
  fr: '👎'
};

export const chat_checkbox_message = (dispatch, form, value, payload, language) => {
  const text = `${form.label}: ${value.checked ? checked[language]: not_checked[language]}`;
  const candidate_answer = {
    sender: 'candidate',
    answers: [text]
  };
  dispatch(moveToHistory());
  dispatch(nextMessage({ next: candidate_answer }));

  payload.text = text;
  payload = {
    ...payload,
    ...value,
    form_id: form.id,
    form_type: form.type,
  };
  return payload;
};

export const validate_checkbox = (forms, idx, form, value) => {

  if (!form.is_mandatory) {
    form.has_error = false;
    form.error_message = '';
    forms[idx] = form;
    return true;
  }

  if (!value || value.checked === undefined || value.checked === null ) {
    form.has_error = true;
    form.error_message = 'This field is mandatory.';
    forms[idx] = form;
    return false;
  } else {
    form.has_error = false;
    form.error_message = '';
    forms[idx] = form;
    return true;
  }
};
