import SimulateMessage from "src/components/Messages/SimulateMessage";

const HubertHistoryMessage = ({ message }) => {
  return (
    <div className="hubert-history-wrapper">
      <div className="hubert-avatar">H</div>
      <div className="w-full flex-1">
        {message.list.map((answer, idx) => {
          return (
            <SimulateMessage
              key={`hubert-history-message-${message.id}-answer-idx-${idx}`}
              wait={0}
              sender="hubert"
              message={message}
              bubble={answer}
              is_last_message={idx === message.list.length - 1}
              messageType="history"
            />
          );
        })}
      </div>
    </div>
  );
};

export default HubertHistoryMessage;
