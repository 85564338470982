import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import CurrentMessage from "../../components/Messages/CurrentMessage";
import MessageHistory from "src/components/MessageHistory";
import MessageTimeProvider from "src/components/Messages/MessageTimeProvider/MessageTimeProvider";
import { InputArea } from "src/components/Input";
import { Header } from "src/components/Header";
import "./Interview.scss";

export const Interview = ({
  job,
  template,
  interview,
  candidate,
  application,
}) => {
  if (!job && !template && !interview && !candidate && !application)
    return <LoadingScreen />;

  return (
    <div id="jarvisContainer" className="jarvis interview-wrapper">
      <div
        id="bubbleContainer"
        className="bubbleChatBox page interview-inner-wrapper"
      >
        <Header interviewLength={interview._state.list.length} />

        {/* Chat */}
        <div id="j-chat" className="j-chat">
          <div
            id="chatContainer"
            className="chatContainer w-100 j-inner chat-container-wrapper"
          >
            <MessageTimeProvider>
              <MessageHistory />
              <CurrentMessage />
            </MessageTimeProvider>
          </div>
        </div>

        {/* Input */}
        <InputArea />
      </div>
    </div>
  );
};
