import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import formatToRelativeTime from "date-fns/formatRelative";
import useScrollToComponent from "src/hooks/useScrollToComponent";

const CandidateMessage = ({ message }) => {
  const answers = useSelector((state) => state.Messages.answers);
  const messagesEndRef = useRef(null);
  const scrollToComponent = useScrollToComponent(messagesEndRef);

  const date =
    message && message.created_at ? new Date(message.created_at) : new Date();

  useEffect(() => {
    scrollToComponent();
  }, [scrollToComponent]);

  return (
    <div ref={messagesEndRef}>
      {answers.list.map((answer, idx) => (
        <span key={`CandidateMessage-${idx}-${date.getTime()}`}>
          <div
            className={
              "me chat-bubble-element-wrapper candidate-message-preview"
            }
          >
            <div className={`chat-bubble-element right`}>
              <div
                className="m-0"
                dangerouslySetInnerHTML={{ __html: answer }}
              ></div>
              <span className="pp right"></span>
            </div>
            <span className="message-time">
              {formatToRelativeTime(date, new Date())}
            </span>
          </div>
          {messagesEndRef && <div ref={messagesEndRef} />}
        </span>
      ))}
    </div>
  );
};

export default CandidateMessage;
