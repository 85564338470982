import { createSlice } from '@reduxjs/toolkit';

import {
  PushReusables,
  SetReusableText,
  Unset,
  UpdateReusableSingleSelectValue,
  UpdateReusableApplicability,
  UpdateReusableDateValue
} from 'src/store/reducers/reusableReducers';

const initialState = {};

const ReusableSlice = createSlice({
  name: 'reusables',
  initialState,
  reducers: {
    pushReusables:                    PushReusables,
    setReusableText:                  SetReusableText,
    unset:                            Unset,
    updateReusableSingleSelectValue:  UpdateReusableSingleSelectValue,
    updateReusableDateValue:          UpdateReusableDateValue,
    updateReusableApplicability:      UpdateReusableApplicability,
  },
  // extraReducers: (builder) => {
  //   builder
  //   .addCase(SendMessage.pending, (state) => {
  //     state.status = 'loading';
  //   })
  //   .addCase(SendMessage.rejected, (state) => {
  //     state.status = 'error';
  //   })
  //   .addCase(SendMessage.fulfilled, (state, action) => {
  //     state.status = 'idle';
  //   });
  // },
});

export const {
  pushReusables,
  setReusableText,
  unset,
  updateReusableSingleSelectValue,
  updateReusableDateValue,
  updateReusableApplicability
} = ReusableSlice.actions;

export default ReusableSlice.reducer;
