import React from 'react';
import Input from './Input';
import './InputArea.scss';

export const InputArea = () => {
  return (
    <div
      id="inputContainer"
      className="inputContainer input-main-wrapper"
    >
      {/* <div className="h-100" id="inputTextarea"> */}
      <Input />

      {/* <span className="powered">Powered by Hubert</span> */}
      {/* </div> */}
    </div>
  );
};
