import React, { useState } from 'react';

export const FormMatrixRow = ({
  form,
  form_idx,
  row_idx,
  values,
  set_values
}) => {
  const [checked_id, set_checked_id] = useState(null);
  const rows = form.rows;
  const row = rows[row_idx];
  const columns = form.columns;

  const on_check = (row_id, column_id) => {
    set_checked_id(column_id);
  };

  return (
  <tr key={`${row.id}-${row_idx}`} className="border ">
    <td className="text-center td-first">
      {row.label}
    </td>
    {columns && columns.length ? (
      columns.map((column, column_idx) => {
        return (
          <td 
            key={`${column.id}-${column_idx}`} 
            className={`text-center`}
            style={{ verticalAlign: 'middle' }}
          >
            <input 
              type="radio"
              name={`${row.id}`}
              id={`${column.id}`}
              value={column.id}
              checked={column.id === checked_id}
              onChange={(e) => {
                let row_values = {};
                if (values.hasOwnProperty(form.id) && values[form.id].row_values) {
                  row_values = values[form.id].row_values;
                };
                const data = {
                  id: form.id,
                  row_values: {
                    ...row_values,
                    [row.id]: {
                      checked: e.target.checked,
                      column_id: column.id,
                      updated_at: new Date()
                    }
                  },
                  updated_at: new Date(),
                  is_mandatory: form.is_mandatory,
                  hubiverse_property: form.hubiverse_property,
                  candidate_property: form.candidate_property,
                };
                set_values({
                  ...values,
                  [form.id]: data
                })
                on_check(row.id, column.id);
              }}
            />
          </td>
        );
      })
    ):(
      <span>Add columns to preview.</span>
    )}
  </tr>
  );
}
