import { createAsyncThunk } from '@reduxjs/toolkit';
import { send_reusables } from 'src/store/api/api';

export const SendReusables = createAsyncThunk(
  'messages/send-reusables',
  async (data) => {
    // The value we return becomes the `fulfilled` action payload
    return await send_reusables(data);
  }
);
