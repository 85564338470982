import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";

import { updateReusableSingleSelectValue } from "src/store/slices/ReusableSlice";

export const ReusableOption = ({
  option,
  qutter_id,
  value,
  original_value,
}) => {
  const dispatch = useDispatch();

  const onOptionClick = () => {
    dispatch(
      updateReusableSingleSelectValue({
        qutter_id,
        value: option.id,
        original_value: original_value,
        label: option.label,
      })
    );
  };

  const clicked = option.id === value;

  return (
    <div
      role="listitem"
      className={clsx(
        clicked && "clicked",
        "flex items-end justify-center q-btn w-full"
      )}
    >
      <button
        className={clsx("input-bubble", clicked && "clicked", "btn w-full")}
        onClick={onOptionClick}
        dangerouslySetInnerHTML={{ __html: option.label }}
      />
    </div>
  );
};
