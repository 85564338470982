import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  sendMessage,
  sendOpenMessage,
  send_form_payloads,
} from "src/store/api/api";
import { updateCandidate } from "../slices/CandidateSlice";

function syncCandidateDataFromServer(dispatch, state, candidateDataFromAPI) {
  const isInSync = ["_id", "first_name", "last_name", "email"].every(
    (property) => candidateDataFromAPI[property] === state.Candidate[property]
  );

  if (!isInSync) {
    const { _id, first_name, last_name, email } = candidateDataFromAPI;

    dispatch(updateCandidate({ _id, first_name, last_name, email }));
  }
}

export const SendOpenMessage = createAsyncThunk(
  "messages/send-open-message",
  async (data, { dispatch, getState }) => {
    const responseData = await sendOpenMessage(data);

    syncCandidateDataFromServer(dispatch, getState(), responseData.candidate);

    return responseData;
  }
);

export const SendMessage = createAsyncThunk(
  "messages/send-message",
  async (data, { dispatch, getState }) => {
    const responseData = await sendMessage(data);

    syncCandidateDataFromServer(dispatch, getState(), responseData.candidate);

    return responseData;
  }
);

export const SendFormPayloads = createAsyncThunk(
  "messages/send-form-payloads",
  async (data, { dispatch, getState }) => {
    const responseData = await send_form_payloads(data);

    syncCandidateDataFromServer(dispatch, getState(), responseData.candidate);

    return responseData;
  }
);
