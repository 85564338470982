import { FC } from 'react';
import classNames from 'classnames';

interface TextProps {
  size?: 'small' | 'large' | '';
  weight?: 'regular' | 'medium' | 'semibold' | 'light';
  block?: boolean;
  className?: string;
  children?: string;
  html?: boolean;
  onClick?: () => void;
}

const Text: FC<TextProps> = ({
  size,
  weight,
  block,
  className,
  children,
  html,
  onClick,
}) => {
  const Tag = block ? 'div' : 'span';
  const textClass = size ? `body-${size}-${weight}` : `body-${weight}`;

  return (
    <Tag className={classNames(textClass, className)} onClick={onClick}>
      {children}
    </Tag>
  );
};

Text.defaultProps = {
  size: '',
  weight: 'regular',
  block: false,
  className: '',
  children: '',
  html: false,
  onClick: () => null,
};

export default Text;
