import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps extends ReactDatePickerProps {}

const DatePicker = ({
  dateFormat = "yyyy-MM-dd",
  placeholderText = "Weeks start on Monday",
  className = "form-control",
  minDate = new Date(),
  inline = true,
  ...props
}: DatePickerProps) => {
  return (
    <div className="hubert-datepicker">
      <ReactDatePicker
        {...props}
        {...{ dateFormat, placeholderText, className, minDate, inline }}
      />
    </div>
  );
};

export default DatePicker;
