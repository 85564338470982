import { FC } from "react";
import { Text } from "../common";
import { useAppSelector } from "../../store/hooks";
import "./OverviewSection.scss";

interface OverviewSectionProps {
  interview: any;
}

const OverviewSection: FC<OverviewSectionProps> = ({ interview }) => {
  const jobDetails: any = useAppSelector((state) => state.Job);
  let logo;
  // let logo =
  //   'https://cdn.freebiesupply.com/logos/large/2x/test-1-logo-png-transparent.png';

  return (
    <div className="overview-section-wrapper">
      <div className="logo-wrapper">
        {logo ? (
          <img src={logo} alt="company logo" />
        ) : (
          <Text weight="semibold" className="company-letter">
            {jobDetails.company.charAt(0)}
          </Text>
        )}
      </div>
      <div className="content-wrapper">
        <Text block weight="semibold" className="title">
          {jobDetails.title}
        </Text>
        <Text block weight="semibold" size="small">
          {jobDetails.company}
        </Text>
        {/* <ProgressBar interviewLength={interview._state.list.length} /> */}
      </div>
    </div>
  );
};

export default OverviewSection;
