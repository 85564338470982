import { forwardRef, useEffect } from "react";
import ReusablesHeader from "./ReusablesHeader";
import ReusablesList from "./ReusablesList";
import SaveReusables from "./SaveReusables";

const ReusablesContainer = forwardRef((_, ref) => {
  useEffect(() => {
    document.getElementById(`${"j-chat"}`).classList.add("full");
  }, []);

  return (
    <section className="reusables-container">
      <ReusablesHeader />
      <ReusablesList ref={ref} />
      <SaveReusables ref={ref} />
    </section>
  );
});

ReusablesContainer.displayName = "ReusablesContainer";

export default ReusablesContainer;
