import React, { useState } from 'react';
import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';

import { FormLabel, FormError } from '../..';

export const FormRange = ({
  form,
  values,
  set_values
}) => {
  const [value, set_value] = useState(values && values.hasOwnProperty(form.id) ? values[form.id].value: [0,0]);
  const min = form.min ? form.min: 0;
  const max = form.max ? form.max: 100;
  const step = form.step ? form.step: 1;

  return (
  <div className="form-group">

    <FormLabel label={form.label} is_mandatory={form.is_mandatory} />
    <FormError has_error={form.has_error} error_message={form.error_message} />

    <div className='d-flex mt-1'>
      <div className='w-25 m-auto text-start'>
        <span>{form.label_left}</span>
      </div>
      <div className='w-50 m-auto text-center'>
        {value && value.length === 2 ? (<span>{value[0]} - {value[1]}</span>): null}
        {form && form.unit ? (<span>({form.unit})</span>): null}
      </div>
      <div className='w-25 text-end'>
        <span>{form.label_right}</span>
      </div>
    </div>

    <div className='ms-6 me-6'>
      <Range
        min={min}
        max={max}
        step={step}
        defaultValue={value}
        onChange={(e) => {
          const data = {
            id: form.id,
            value: e,
            range_min: e[0],
            range_max: e[1],
            updated_at: new Date(),
            is_mandatory: form.is_mandatory,
            hubiverse_property: form.hubiverse_property,
            candidate_property: form.candidate_property,
          };
          set_values({
            ...values,
            [form.id]: data
          })
          set_value(e)
        }}
      />
    </div>
  </div>
  );
}
