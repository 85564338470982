import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import HubertMessage from "./HubertMessage/HubertMessage";
import CandidateMessage from "./CandidateMessage/CandidateMessage";
import TypingIndicator from "../TypingIndicator";
import useScrollToComponent from "src/hooks/useScrollToComponent";

const CurrentMessage = () => {
  const answers = useSelector((state) => state.Messages.answers);
  const current = useSelector((state) => state.Messages.current);
  const isTyping = useSelector((state) => state.Messages.htyping);
  const messagesEndRef = useRef(null);
  const scrollToMessage = useScrollToComponent(messagesEndRef);

  useEffect(() => {
    if (current && current.type === "open") {
      document.getElementById(`${"j-chat"}`).classList.remove("full");
    } else {
      document.getElementById(`${"j-chat"}`).classList.add("full");
    }
    scrollToMessage();
  }, [current, scrollToMessage]);

  if (!answers) return null;
  let wait = answers.wait;

  let t = 0;
  const delay = 500;
  t += delay;
  t = answers.list.length * 150;
  return (
    <>
      {answers.sender === "hubert" ? (
        <div className="extra-bodil hubert-messages-wrapper">
          <HubertMessage wait={wait !== undefined ? wait : t} message_idx={0} />
        </div>
      ) : (
        <div className="extra-me candidate-messages-wrapper">
          <CandidateMessage wait={0} message_idx={0} />
        </div>
      )}
      {isTyping && <TypingIndicator />}
    </>
  );
};

export default CurrentMessage;
