import React from 'react';

export const FormLabel = ({
  label,
  is_mandatory
}) => {
  return (
    <div className='d-flex'>
      <span
        dangerouslySetInnerHTML={{ __html: `${label}` }}
        style={{ maxWidth: '90%', verticalAlign: 'middle' }}
        className="m-0"
      >
      </span>
      {is_mandatory ? (<span className=''>*</span>): (<span></span>)}
    </div>
  );
}
