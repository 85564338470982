import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormContainer, FormElement } from "..";

import { validate_text, chat_text_message } from "./text/utils";
import { validate_number, chat_number_message } from "./number/utils";
import { validate_email, chat_email_message } from "./email/utils";
import { validate_slider, chat_slider_message } from "./slider/utils";
import { validate_checkbox, chat_checkbox_message } from "./checkbox/utils";
import { validate_range, chat_range_message } from "./range/utils";
import { validate_matrix, chat_matrix_message } from "./matrix/utils";
import {
  moveToHistory,
  setCurrent,
  toggleRendering,
  setPending,
} from "src/store/slices/MessageSlice";

import { SendFormPayloads } from "src/store/thunks/MessageThunk";
import "./Forms.scss";

export const Forms = ({ message }) => {
  const dispatch = useDispatch();
  const Interview = useSelector((state) => state.Interview);
  const question = useSelector((state) => state.Messages.current);
  const rendering = useSelector((state) => state.Messages.is_rendering);

  const [forms, set_forms] = useState([]);
  const [has_error, set_has_error] = useState(false);
  const [error_message, set_error_message] = useState("");
  const [values, set_values] = useState({});

  useEffect(() => {
    set_forms(message.forms);
    let v = {};
    for (let i = 0; i < message.forms.length; i++) {
      const form = message.forms[i];
      v[form.id] = {
        id: form.id,
        is_mandatory: form.is_mandatory,
        hubiverse_property: form.hubiverse_property,
        candidate_property: form.candidate_property,
      };
      if (form.type === "slider") {
        v[form.id].value = 0;
      }
      if (form.type === "text") {
        v[form.id].value = "";
      }
      if (form.type === "email") {
        v[form.id].value = "";
      }
    }
    set_values(v);
    document.getElementById(`${"j-chat"}`).classList.add("full");
  }, [message.forms]);

  const validate = () => {
    let temp_forms = JSON.parse(JSON.stringify(forms));
    let validated = [];

    for (let i = 0; i < forms.length; i++) {
      let form = Object.assign({}, forms[i]);

      if (form.type === "text")
        validated.push(validate_text(temp_forms, i, form, values[form.id]));
      else if (form.type === "number")
        validated.push(validate_number(temp_forms, i, form, values[form.id]));
      else if (form.type === "email")
        validated.push(validate_email(temp_forms, i, form, values[form.id]));
      else if (form.type === "slider")
        validated.push(validate_slider(temp_forms, i, form, values[form.id]));
      else if (form.type === "range")
        validated.push(validate_range(temp_forms, i, form, values[form.id]));
      else if (form.type === "checkbox")
        validated.push(validate_checkbox(temp_forms, i, form, values[form.id]));
      else if (form.type === "matrix")
        validated.push(validate_matrix(temp_forms, i, form, values[form.id]));
    }

    if (validated.includes(false)) {
      set_forms(temp_forms);
      set_error_message("Form is not filled in correctly.");
      set_has_error(true);
      return false;
    }

    set_error_message("");
    set_has_error(false);
    return true;
  };

  const onSendClick = async () => {
    const is_valid = await validate();
    if (is_valid) send();
  };

  const send = async () => {
    let data = {
      interview_id: Interview._id,
      candidate_id: Interview.candidate_id,
      job_id: Interview.job_id,
      template_id: Interview.template_id,
      application_id: Interview.application_id,
      qutter_oid: question._id,
      qutter_id: question.id,
      payload: [],
    };

    for (let i = 0; i < message.forms.length; i++) {
      let form = message.forms[i];
      let payload = {
        interview_id: Interview._id,
        candidate_id: Interview.candidate_id,
        job_id: Interview.job_id,
        template_id: Interview.template_id,
        application_id: Interview.application_id,
        qutter_oid: question._id,
        qutter_id: question.id,
        language: question.language,
        expected_language: question.language,
        type: question.type,
        sender: "candidate",
      };

      if (form.type === "text") {
        data.payload.push(
          chat_text_message(dispatch, form, values[form.id], payload)
        );
      }
      if (form.type === "number") {
        data.payload.push(
          chat_number_message(dispatch, form, values[form.id], payload)
        );
      }
      if (form.type === "email") {
        data.payload.push(
          chat_email_message(dispatch, form, values[form.id], payload)
        );
      }
      if (form.type === "slider") {
        data.payload.push(
          chat_slider_message(dispatch, form, values[form.id], payload)
        );
      }
      if (form.type === "checkbox") {
        data.payload.push(
          chat_checkbox_message(
            dispatch,
            form,
            values[form.id],
            payload,
            question.language
          )
        );
      }
      if (form.type === "range") {
        data.payload.push(
          chat_range_message(dispatch, form, values[form.id], payload)
        );
      }
      if (form.type === "matrix") {
        const d = await chat_matrix_message(
          dispatch,
          form,
          values[form.id],
          payload
        );
        data.payload = data.payload.concat(d);
      }
    }

    dispatch(SendFormPayloads(data))
      .then((res) => {
        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());
        dispatch(setCurrent({ current: res.payload.data }));
        dispatch(setPending({ pending: res.payload.pending }));
      })
      .catch((error) => {
        console.log("SendFormPayloads Error: ", error);
      });
  };

  return (
    <FormContainer
      forms={forms}
      submitBtnText={message.send_text}
      onSubmit={onSendClick}
    >
      <div>
        <span className="h4">{message.help_text}</span>
      </div>

      {forms.map((form, form_idx) => {
        return (
          <FormElement
            key={`form-key-${form.id}-${form_idx}`}
            form={form}
            form_idx={form_idx}
            values={values}
            set_values={set_values}
          />
        );
      })}

      {/* <FormError has_error={has_error} error_message={error_message} /> */}
    </FormContainer>
  );
};

Forms.displayName = "Forms";
