import { useSelector } from "react-redux";
import ReusableOpen from "../components/ReusableOpen";
import { ReusableSingleSelect } from "./SingleSelect/ReusableSingleSelect";
import { ReusableDate } from "./ReusableDate/ReusableDate";
import { forwardRef } from "react";

const ReusablesList = forwardRef((_, ref) => {
  const order = useSelector((state) => state.Reusables.order);
  const map = useSelector((state) => state.Reusables.map);

  return order.map((id, idx) => {
    const reusable = map[id];
    switch (reusable.question.type) {
      case "open":
        return (
          <ReusableOpen
            key={`reusable-${reusable.qutter_id}-${idx}`}
            ref={ref}
            reusable={reusable}
            reusable_idx={idx}
          />
        );
      case "single-select":
        return (
          <ReusableSingleSelect
            key={`reusable-${reusable.qutter_id}-${idx}`}
            ref={ref}
            reusable={reusable}
            reusable_idx={idx}
          />
        );
      case "multiple-select":
        return (
          <div
            ref={ref}
            className="text-center m-2"
            style={{ fontSize: "16px" }}
          >
            <i>
              <p
                className="m-0"
                dangerouslySetInnerHTML={{
                  __html: `${reusable.question.type}: ${reusable.question.text}`,
                }}
              ></p>
            </i>
          </div>
        );
      case "date-picker":
        return (
          <ReusableDate
            // ref={ref}
            key={`reusable-${reusable.qutter_id}-${idx}`}
            reusable={reusable}
            reusable_idx={idx}
          />
        );
      case "month-picker":
        return (
          <div
            ref={ref}
            className="text-center m-2"
            style={{ fontSize: "16px" }}
          >
            <i>
              <p
                className="m-0"
                dangerouslySetInnerHTML={{
                  __html: `${reusable.question.type}: ${reusable.question.text}`,
                }}
              ></p>
            </i>
          </div>
        );
      default:
        return null;
    }
  });
});

export default ReusablesList;
