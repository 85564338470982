import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Send } from "react-feather";
import {
  removeOptions,
  moveToHistory,
  nextMessage,
  setCurrent,
  toggleRendering,
} from "src/store/slices/MessageSlice";

import { SendMessage } from "src/store/thunks/MessageThunk";
import MultipleSelectElement from "./MultipleSelectElement";
import { Text } from "src/components/common";
import "./MultipleSelect.scss";

export const MultipleSelect = forwardRef(({ message_idx }, ref) => {
  const dispatch = useDispatch();
  const Interview = useSelector((state) => state.Interview);
  const question = useSelector((state) => state.Messages.current);

  const [selection, setSelection] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    document.getElementById(`${"j-chat"}`).classList.add("full");
  }, []);

  const onChange = (newVal) => {
    setSelection(newVal);
  };

  const send = () => {
    let selected = [];
    let candidate_answers = [];
    selection.map((option) => {
      if (option.value !== "all-above") {
        selected.push(option.id);
        candidate_answers.push(option.label);
      }
    });
    if (selected.length === 0) {
      return setErrorMessage("You must do something.");
    } else setErrorMessage("");

    const candidate_answer = {
      sender: "candidate",
      answers: candidate_answers,
    };
    dispatch(removeOptions({ idx: message_idx }));
    dispatch(moveToHistory());
    dispatch(nextMessage({ next: candidate_answer }));

    dispatch(
      SendMessage({
        interview_id: Interview._id,
        candidate_id: Interview.candidate_id,
        job_id: Interview.job_id,
        template_id: Interview.template_id,
        application_id: Interview.application_id,
        qutter_oid: question._id,
        qutter_id: question.id,
        language: question.language,
        type: question.type,

        label: candidate_answers,
        value: selected,
      })
    )
      .then((res) => {
        // document.getElementById(`${'j-chat'}`).classList.remove('full');
        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());
        // dispatch(nextMessage({ next: res.payload.data }));
        dispatch(setCurrent({ current: res.payload.data }));
      })
      .catch((res) => {
        console.log("SendMessage Thunk Error: ", res);
      });
  };

  return (
    <form
      ref={ref}
      className="multiple-select-wrapper"
      id={`${question._id}`}
      qid={`${question.id}`}
    >
      <Text weight="semibold">
        {["en", "en-us", "en-gb"].includes(Interview._state.language)
          ? "Click the options that best suit you in the multi-choice selector below."
          : "Klicka i de alternativ som passar bäst i flervalsväljaren nedan."}
      </Text>

      <div className="selects-wrapper">
        <MultipleSelectElement options={question.options} onChange={onChange} />
      </div>

      {errorMessage && (
        <div
          id="input_error_container"
          className="text-center w-100"
          style={{ minHeight: "18px" }}
        >
          <p
            id="input_error"
            className={`m-0 ${errorMessage ? errorMessage : "hidden"}`}
            style={{ color: "red" }}
          >
            {errorMessage}
          </p>
        </div>
      )}

      <div id="formButton" className="send-button" onClick={send}>
        <span>
          {["en", "en-us", "en-gb"].includes(Interview._state.language)
            ? "Send"
            : "Skicka"}
        </span>
        <Send size={16} />
      </div>
    </form>
  );
});
