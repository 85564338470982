import { moveToHistory, nextMessage } from "src/store/slices/MessageSlice";

export const chat_range_message = (dispatch, form, value, payload) => {
  let candidate_answer;
  let text = '';
  if (form.is_mandatory) {
    text = `${form.label}: ${value.value[0]} - ${value.value[1]}`;
  } else {
    if (value.value.length === 2) {
      text = `${form.label}: ${value.value[0]} - ${value.value[1]}`;
    } else 
    if (value.value.length === 1) {
      text = `${form.label}: ${value.value[0]} - N/A`;
    } else {
      text = `${form.label}: N/A - N/A`;
    }
  }
  candidate_answer = {
    sender: 'candidate',
    answers: [text]
  };

  dispatch(moveToHistory());
  dispatch(nextMessage({ next: candidate_answer }));

  payload.text = text;
  payload = {
    ...payload,
    ...value,
    form_id: form.id,
    form_type: form.type,
  };
  return payload;
};

export const validate_range = (forms, idx, form, value) => {

  if (!form.is_mandatory) {
    form.has_error = false;
    form.error_message = '';
    forms[idx] = form;
    return true;
  }

  if (!value || !value.value || value.value.length !== 2) {
    form.has_error = true;
    form.error_message = 'This field is mandatory.';
    forms[idx] = form;
    return false;
  } else {
    form.has_error = false;
    form.error_message = '';
    forms[idx] = form;
    return true;
  }
};
