
export function write_something (language) {
  let msg = '';

  switch (language) {
    case 'sv':
      msg = 'Skriv ett meddelande...';
      break;
    case 'en':
      msg = 'Write something...';
      break;
    case 'en-gb':
      msg = 'Write something...';
      break;
    case 'en-us':
      msg = 'Write something...';
      break;
    case 'no':
      msg = 'Translate NO';
      break;
    case 'dk':
      msg = 'Translate DK';
      break;
    case 'fi':
      msg = 'Translate FI';
      break;
    case 'de':
      msg = 'Translate DE';
      break;
    case 'es':
      msg = 'Translate ES';
      break;
    case 'fr':
      msg = 'Translate FR';
      break;
    default:
      msg = 'Skriv ett meddelande...';
  }
  return msg;
}