import React, { forwardRef, useEffect } from "react";
import Option from "./Option";
import { makeid } from "src/utils/utils";
import "./SingleSelect.scss";

export const SingleSelect = forwardRef(({ message, message_idx }, ref) => {
  useEffect(() => {
    document.getElementById(`${"j-chat"}`).classList.add("full");
  }, []);

  if (!message || !message.options || !message.options.length) {
    return null;
  }

  return (
    <div ref={ref} className="single-select-wrapper" id={`${makeid(8)}`}>
      {message.options.map((option, idx) => {
        return (
          <Option
            key={`${makeid(8)}`}
            message={message}
            message_idx={message_idx}
            option_id={option.id}
            option_idx={idx}
          />
        );
      })}
    </div>
  );
});
