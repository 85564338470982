import { createSlice } from '@reduxjs/toolkit';

import {
  // General Functionality
  PushMessage,
  NextMessage,
  SetCurrent,
  SetPending,
  MoveToHistory,
  PushToHistory,
  ToggleHubertTyping,
  ToggleCandidateTyping,
  ToggleRendering,
  RemoveOptions,
  SetSelectedOption
} from 'src/store/reducers/messageReducers';

import { 
  SendMessage,
  SendFormPayloads
} from 'src/store/thunks/MessageThunk';

const initialState = {
  history: [],
  next: null,
  current: null,
  answers: {
    sender: 'hubert',
    list: []
  },
  ctyping: false,
  htyping: true,
  pending: null,
  is_rendering: true
};

const MessageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    pushMessage: PushMessage,
    nextMessage: NextMessage,
    setCurrent: SetCurrent,
    setPending: SetPending,
    moveToHistory: MoveToHistory,
    pushToHistory: PushToHistory,

    // Typing
    toggleHubertTyping: ToggleHubertTyping,
    toggleCandidateTyping: ToggleCandidateTyping,
    toggleRendering: ToggleRendering,
    removeOptions: RemoveOptions,
    setSelectedOption: SetSelectedOption
  },
  extraReducers: (builder) => {
    builder
    .addCase(SendMessage.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(SendMessage.rejected, (state) => {
      state.status = 'error';
    })
    .addCase(SendMessage.fulfilled, (state, action) => {
      state.status = 'idle';
    })

    .addCase(SendFormPayloads.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(SendFormPayloads.rejected, (state) => {
      state.status = 'error';
    })
    .addCase(SendFormPayloads.fulfilled, (state, action) => {
      state.status = 'idle';
    })
  },
});

export const {
  pushMessage,
  nextMessage,
  setCurrent,
  setPending,
  moveToHistory,
  pushToHistory,

  // Toggles
  toggleRendering,
  toggleHubertTyping,
  toggleCandidateTyping,

  // Option updates
  removeOptions,
  setSelectedOption
} = MessageSlice.actions;
export default MessageSlice.reducer;
