import { moveToHistory, nextMessage } from "src/store/slices/MessageSlice";

export const chat_number_message = (dispatch, form, value, payload) => {
  value.value = Number(value.value);
  const text = `${form.label}: ${value.value}`;
  const candidate_answer = {
    sender: 'candidate',
    answers: [`${text}`]
  };
  dispatch(moveToHistory());
  dispatch(nextMessage({ next: candidate_answer }));

  payload.text = text;
  payload = {
    ...payload,
    ...value,
    form_id: form.id,
    form_type: form.type,
  };
  return payload;
};

export const validate_number = (forms, idx, form, value) => {

  if (!form.is_mandatory) {
    form.has_error = false;
    form.error_message = '';
    forms[idx] = form;
    return true;
  }

  if (!value || !value.value || !value.value.length) {
    form.has_error = true;
    form.error_message = 'This field is mandatory.';
    forms[idx] = form;
    return false;
  } else {
    form.has_error = false;
    form.error_message = '';
    forms[idx] = form;
    return true;
  }
};
