
export function PushReusables (state, action) {
  const { reusable } = action.payload;
  for (const key in reusable) {
    state[key] = reusable[key];
  }
  state.reusable = reusable;
};

export function Unset (state, action) {
  state.reusable = null;
};

export function SetReusableText (state, action) {
  const { qutter_id, text } = action.payload;
  const map = state.map;
  map[qutter_id].texts = [text];
  map[qutter_id].reusage.is_edited = true;
};

export function UpdateReusableApplicability (state, action) {
  const { qutter_id, applicable } = action.payload;
  const map = state.map;
  map[qutter_id].applicable = applicable;
};

export function UpdateReusableSingleSelectValue (state, action) {
  const { qutter_id, value, original_value, label } = action.payload;
  const map = state.map;
  map[qutter_id].value = value;
  map[qutter_id].label = label;
  if (value === original_value) {
    map[qutter_id].reusage.is_edited = false;
  } else {
    map[qutter_id].reusage.is_edited = true;
  }
};

export function UpdateReusableDateValue (state, action) {
  const { qutter_id, value, original_value, label } = action.payload;
  const map = state.map;
  map[qutter_id].value = value;
  map[qutter_id].label = label;
  if (value === original_value) {
    map[qutter_id].reusage.is_edited = false;
  } else {
    map[qutter_id].reusage.is_edited = true;
  }
};
