import axios from 'axios';

const base = process.env.REACT_APP_URL ? process.env.REACT_APP_URL: 'http://localhost:4001';
const CLOSED_URL        = `${base}/interview/v1/reply`;
const OPEN_URL          = `${base}/interview/v1/open-reply`;
const REUSABLE_URL      = `${base}/interview/v1/reuseable-reply`;
const FORM_PAYLOADS_URL = `${base}/interview/v1/form-payloads`;

export function sendMessage (data) {
  return new Promise((resolve, reject) => {
    axios.post(`${CLOSED_URL}`, data)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => { reject(error); });
  });
}

export function sendOpenMessage (data) {
  return new Promise((resolve, reject) => {
    axios.post(`${OPEN_URL}`, data)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => { reject(error); });
  });
}

export function send_reusables (data) {
  return new Promise((resolve, reject) => {
    axios.post(`${REUSABLE_URL}`, data)
    .then(result => {
      resolve(result.data);
    })
    .catch(error => { reject(error); });
  });
}

export function send_form_payloads (data) {
  return new Promise((resolve, reject) => {
    axios.post(`${FORM_PAYLOADS_URL}`, { data: data })
    .then(result => {
      resolve(result.data);
    })
    .catch(error => {
      reject(error); 
    });
  });
}
