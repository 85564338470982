import {
  FormText,
  FormNumber,
  FormEmail,
  FormSlider,
  FormRange,
  FormCheckbox,
  FormMatrix,
} from "..";

export const FormElement = (props) => {
  if (!props.form.type) {
    return null;
  }

  switch (props.form.type) {
    case "text":
      return <FormText {...props} />;

    case "number":
      return <FormNumber {...props} />;

    case "email":
      return <FormEmail {...props} />;

    case "slider":
      return <FormSlider {...props} />;

    case "range":
      return <FormRange {...props} />;

    case "checkbox":
      return <FormCheckbox {...props} />;

    case "matrix":
      return <FormMatrix {...props} />;

    default:
      return null;
  }
};
