import { useState, useEffect, useMemo } from "react";

type FixedBreakpoints = "sm" | "md" | "lg" | "xl";

interface UseMediaQuery {
  breakpoint: FixedBreakpoints;
  query?: string;
}

function useMediaQuery({ breakpoint, query }: UseMediaQuery): boolean {
  const usedQuery = useMemo(() => {
    if (query) {
      return query;
    }

    switch (breakpoint) {
      case "sm":
        return "(max-width: 640px)";
      case "md":
        return "(max-width: 768px)";
      case "lg":
        return "(max-width: 1024px)";
      case "xl":
        return "(max-width: 1280px)";
      default:
        return "";
    }
  }, [query, breakpoint]);

  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(usedQuery));

  function handleChange() {
    setMatches(getMatches(usedQuery));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(usedQuery);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener("change", handleChange);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedQuery]);

  return matches;
}

export default useMediaQuery;
