import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { update_questions_Left } from "./utils/utils";

const QuestionProgress = ({ interview }) => {
  const [total_questions, set_total_questions] = useState(null);
  const [width, set_width] = useState(0);
  const [pending_text, set_pending_text] = useState(null);
  const pending = useSelector((state) => state.Messages.pending);

  useEffect(() => {
    if (
      interview &&
      interview._state &&
      interview._state.list &&
      interview._state.list.length
    ) {
      set_total_questions(interview._state.list.length);
    }
  }, []);

  useEffect(() => {
    let text = "";
    if (!total_questions) {
      let progress_text_map = {
        sv: `Inga frågor kvar`,
        en: `No questions left`,
        "en-gb": `No questions left`,
        "en-us": `No questions left`,
        de: `Nein etwa Fragen verbleibend`,
        es: `Quedan zero preguntas`,
      };
      text = progress_text_map[interview._state.language];
    } else {
      let progress_text_map = {
        sv: `Omkring ${total_questions} frågor kvar`,
        en: `About ${total_questions} questions left`,
        "en-gb": `About ${total_questions} questions left`,
        "en-us": `About ${total_questions} questions left`,
        de: `Noch etwa ${total_questions} Fragen verbleibend`,
        es: `Quedan unas ${total_questions} preguntas`,
      };
      text = progress_text_map[interview._state.language];
    }
    set_pending_text(text);
  }, [total_questions]);

  useEffect(() => {
    if (pending === null) return;
    (async () => {
      const update = await update_questions_Left(
        interview._state.language,
        total_questions,
        width,
        pending
      );

      if (update) {
        set_width(update.percent_done);
        set_pending_text(update.pending_text);
      }
    })();
  }, [pending]);

  if (total_questions === null && pending_text === null) return null;

  return (
    <div className="progressbarContainer" id="progressbarContainer">
      <div id="progessbar" className="progress prog-bar">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${width}%`,
          }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <div id="progressText">{pending_text}</div>
    </div>
  );
};

export default QuestionProgress;
