// import { composeWithDevTools } from 'redux-devtools-extension';
import { configureStore } from '@reduxjs/toolkit';
import JobSlice from './slices/JobSlice';
import TemplateSlice from './slices/TemplateSlice';
import CandidateSlice from './slices/CandidateSlice';
import InterviewSlice from './slices/InterviewSlice';
import ApplicationSlice from './slices/ApplicationSlice';
import MessageSlice from './slices/MessageSlice';
import QmetricSlice from './slices/QmetricSlice';
import ReusableSlice from './slices/ReusableSlice';

export const store = configureStore({
  reducer: {
    Qmetrics: QmetricSlice,
    Job: JobSlice,
    Template: TemplateSlice,
    Candidate: CandidateSlice,
    Interview: InterviewSlice,
    Application: ApplicationSlice,
    Messages: MessageSlice,
    Reusables: ReusableSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;