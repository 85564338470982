import { createSlice } from '@reduxjs/toolkit';
const initialState = {};

const InterviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    initInterview (state, action) {
      const { interview } = action.payload;
      return {
        ...state,
        ...interview
      };
    }
  }
});

export const {
  initInterview
} = InterviewSlice.actions;
export default InterviewSlice.reducer;
