import { Table } from "react-bootstrap";

import { FormLabel, FormError } from "../..";
import { FormMatrixRow } from "./FormMatrixRow";

export const FormMatrix = ({ form, form_idx, values, set_values }) => {
  const rows = form.rows;
  const columns = form.columns;

  return (
    <div className="form-group">
      <FormLabel label={form.label} is_mandatory={form.is_mandatory} />
      <FormLabel label={form.help_text} />
      <FormError
        has_error={form.has_error}
        error_message={form.error_message}
      />

      <div className="max-w-[600px] overflow-x-scroll">
        <Table borderless className="min-w-[800px]">
          <thead>
            <tr>
              <th></th>
              {columns && columns.length ? (
                columns.map((column, column_idx) => {
                  return (
                    <th
                      key={`${column.id}-${column_idx}`}
                      className="text-center summery-icon"
                    >
                      {column.label}
                    </th>
                  );
                })
              ) : (
                <span>Add columns to preview.</span>
              )}
            </tr>
          </thead>
          <tbody>
            {rows && rows.length ? (
              rows.map((row, row_idx) => {
                return (
                  <FormMatrixRow
                    key={`${row.id}-${row_idx}`}
                    form={form}
                    form_idx={form_idx}
                    row_idx={row_idx}
                    values={values}
                    set_values={set_values}
                  />
                );
              })
            ) : (
              <span>Add rows to preview.</span>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
