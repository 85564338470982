import { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";

import { write_something } from "src/utils/constants/constants";

import {
  nextMessage,
  moveToHistory,
  toggleHubertTyping,
  toggleCandidateTyping,
  setCurrent,
} from "src/store/slices/MessageSlice";

import { SendMessage } from "src/store/thunks/MessageThunk";

import {
  SetEndTime,
  Reset,
  VisibilityChange,
  Pastes,
} from "src/store/slices/QmetricSlice";

import { setReusableText } from "src/store/slices/ReusableSlice";
import { onInput, onKeyDown, onKeyUp, onChange } from "../../utils/utils";

const ReusableInput = forwardRef(({ reusable, edit }, ref) => {
  const dispatch = useDispatch();
  const env = process.env.NODE_ENV ? process.env.NODE_ENV : "development";

  const qutter_id = reusable.qutter_id;
  const qutter_oid = reusable.qutter_oid;

  const [is_disabled, set_is_disabled] = useState(true);
  const [ship, set_ship] = useState(false);
  const [current_text, set_current_text] = useState("");
  const [texts, set_texts] = useState([]);
  const [units, set_units] = useState([]);
  const [interval, set_interval] = useState(null);

  const Interview = useSelector((state) => state.Interview);
  const question = useSelector((state) => state.Messages.current);
  const ctyping = useSelector((state) => state.Messages.ctyping);
  const qmetrics = useSelector((state) => state.Qmetrics[qutter_id]);

  const handle_visibility_change = (event) => {
    // TODO: Visibility change - slice and update
    try {
      let visibility_change = null;
      if (document.hidden) visibility_change = { left: true, date: new Date() };
      if (!document.hidden)
        visibility_change = { returned: true, date: new Date() };
      if (visibility_change !== null)
        dispatch(
          VisibilityChange({
            qutter_id: question.id,
            visibility_change,
          })
        );
    } catch (e) {
      console.log("Catched Error: ", e);
    }
  };

  useEffect(() => {
    if (reusable) {
      set_current_text(reusable.texts);
    }
  }, []);

  useEffect(() => {
    if (is_disabled) set_is_disabled(false);
  }, [qutter_id]);

  useEffect(() => {
    if (is_disabled) set_is_disabled(false);
  }, [qmetrics]);

  useEffect(() => {
    if (!ctyping && ship === true && texts.length > 0) {
      set_is_disabled(true);
      dispatch(toggleCandidateTyping({ value: false }));

      dispatch(toggleHubertTyping({ value: true }));

      const payload = {
        interview_id: Interview._id,
        candidate_id: Interview.candidate_id,
        job_id: Interview.job_id,
        template_id: Interview.template_id,
        application_id: Interview.application_id,
        qutter_oid: qutter_oid,
        qutter_id: qutter_id,
        language: reusable.language,
        type: "open",

        text: current_text,
        messages: current_text,
        texts: texts,
        units: units,
      };

      dispatch(SendMessage(payload))
        .then((res) => {
          set_texts([]);
          set_units([]);
          set_ship(false);
          dispatch(moveToHistory());
          dispatch(setCurrent({ current: res.payload.data }));
        })
        .catch((error) => {
          console.log("SendMessage Open Thunk Error: ", error);
        });
    }
  }, [ship, ctyping]);

  // useEffect(() => {
  //   const element = ref.current;
  //   document.addEventListener('visibilitychange', handle_visibility_change);
  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener('visibilitychange', handle_visibility_change);
  //   };
  // }, []);

  useEffect(() => {
    if (texts.length > 0) {
      const end = new Date();
      end.setSeconds(end.getSeconds() + 5);

      if (!ctyping && interval === null) {
        function inner_interval(ctyping, interval, set_ship) {
          const now = new Date().getTime();
          const distance = end - now;
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          if (env !== "production") {
            document.getElementById("hubert-responds-time-left").innerHTML =
              seconds;
            document
              .getElementById("hubert-responds-container")
              .classList.remove("d-none");
          }
          if (ctyping && interval.interval) {
            clearInterval(x);
            interval.set_interval(null);

            if (env !== "production") {
              document.getElementById("hubert-responds-time-left").innerHTML =
                "";
              document
                .getElementById("hubert-responds-container")
                .classList.add("d-none");
            }
            set_ship(false);
            return;
          } else if (!ctyping && distance < 0) {
            clearInterval(x);
            interval.set_interval(null);

            if (env !== "production") {
              document.getElementById("hubert-responds-time-left").innerHTML =
                "";
              document
                .getElementById("hubert-responds-container")
                .classList.add("d-none");
            }

            set_ship(true);
            return;
          }
        }
        const x = setInterval(function () {
          inner_interval(ctyping, { interval, set_interval }, set_ship);
        }, 100);
        set_interval(x);
      }
    }
  }, [ctyping]);

  return (
    <div className="edit-textbox-wrapper">
      <Form.Control
        className="reusable-text-form-input"
        id={`reusable-input-${qutter_id}`}
        as="textarea"
        placeholder={write_something(Interview._state.language)}
        ref={ref}
        disabled={is_disabled}
        onKeyDown={(e) => {
          onKeyDown(e, dispatch, qutter_id);
        }}
        onInput={(e) => {
          onInput(e, dispatch, qutter_id);
        }}
        onChange={(e) => {
          onChange(e, dispatch, set_current_text);
        }}
        onKeyUp={(e) => {
          onKeyUp(e, set_current_text, function () {
            dispatch(toggleCandidateTyping({ value: false }));
            const candidate_answer = {
              sender: "candidate",
              answers: [`${current_text}`],
            };

            dispatch(
              SetEndTime({ qutter_id: qutter_id, end: new Date().getTime() })
            );
            set_texts([...texts, current_text]);
            set_units([...units, qmetrics]);
            set_current_text("");
            set_ship(false);

            dispatch(Reset({ qutter_id: qutter_id }));
            dispatch(moveToHistory());
            dispatch(nextMessage({ next: candidate_answer }));
          });
        }}
        onPaste={(e) => {
          const text = e.clipboardData.getData("Text");
          dispatch(
            Pastes({
              qutter_id,
              text,
            })
          );
        }}
        value={current_text}
      />

      {edit.edit && (
        <div className="save-button-wrapper">
          <div
            className="save-button"
            onClick={() => {
              edit.set_edit(!edit.edit);
              dispatch(
                setReusableText({
                  qutter_id,
                  text: current_text,
                })
              );
            }}
          >
            Save
          </div>
        </div>
      )}
    </div>
  );
});

export default ReusableInput;
