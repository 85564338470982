import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, Outlet } from "react-router-dom";
import axios from "axios";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { initJob } from "src/store/slices/JobSlice";
import { initTemplate } from "src/store/slices/TemplateSlice";
import { initCandidate } from "src/store/slices/CandidateSlice";
import { initApplication } from "src/store/slices/ApplicationSlice";
import { initInterview } from "src/store/slices/InterviewSlice";
import { pushToHistory } from "src/store/slices/MessageSlice";

const base = process.env.REACT_APP_URL
  ? process.env.REACT_APP_URL
  : "http://localhost:4001";
const URL = `${base}/interview/v1`;

export const LoadPreview = () => {
  const dispatch = useDispatch();
  let { interview_id } = useParams();
  const [loading, setLoading] = useState(true);

  const [template, setTemplate] = useState(null);
  const [candidate, setCandidate] = useState(null);
  const [application, setApplication] = useState(null);
  const [job, setJob] = useState(null);
  const [interview, setInterview] = useState(null);

  useEffect(() => {
    axios
      .get(`${URL}/preview/${interview_id}`)
      .then((res) => {
        const data = res.data;

        setTemplate(data.template);
        setCandidate(data.candidate);
        setApplication(data.application);
        setJob(data.job);
        setInterview(data.interview);

        dispatch(pushToHistory({ history: data.history }));
        dispatch(initJob({ job: data.job }));
        dispatch(initTemplate({ template: data.template }));
        dispatch(initCandidate({ candidate: data.candidate }));
        dispatch(initApplication({ application: data.application }));
        dispatch(initInterview({ interview: data.interview }));

        // const next = data.interview._state.current;
        // dispatch(nextMessage({ next }));
        // dispatch(setCurrent({ current: next }));
        setLoading(false);
      })
      .catch((err) => {
        console.log("Axios Error ViewInterview: ", err);
      });
  }, []);

  return (
    <div>
      {!loading ? (
        <Outlet context={[template, candidate, application, job, interview]} />
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};
