import { useSelector } from "react-redux";

import HubertHistoryMessage from "./HubertHistoryMessage";
import CandidateHistoryMessage from "./CandidateHistoryMessage";

const MessageHistory = () => {
  const history = useSelector((state) => state.Messages.history);

  return (
    <>
      {history.map((message, idx) => {
        if (message.sender === "hubert") {
          return (
            <div
              className="extra-bodil hubert-messages-wrapper"
              key={`HubertHistoryMessage-container-${idx}`}
            >
              <HubertHistoryMessage message={message} message_idx={idx} />
            </div>
          );
        } else {
          return (
            <div
              className="extra-me candidate-messages-wrapper"
              key={`CandidateHistoryMessage-container-${idx}`}
            >
              <CandidateHistoryMessage message={message} message_idx={idx} />
            </div>
          );
        }
      })}
    </>
  );
};

export default MessageHistory;
